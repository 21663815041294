import type { Creator } from 'profile/types/profile'
import type { CreatorStat } from 'account/types/creator-stat'

export default (creator: Creator): CreatorStat[] => {
  return [
    {
      name: 'From',
      value: `${creator.state ? `${creator.state}, ` : ''}${creator.country}`,
      type: 'text',
      icon: 'IconMapPin',
    },
    {
      name: 'Projects on queue',
      value: creator.projectsInQueue,
      type: 'text',
      icon: 'IconQueue',
    },
    {
      name: 'Total projects',
      value: creator.totalProjects,
      type: 'text',
      icon: 'IconFolderOpen',
    },
    {
      name: 'Delivery on time',
      value: creator.deliveryOnTime?.replace('%', ''),
      type: creator.deliveryOnTime === '--' ? 'text' : 'progress',
      icon: 'IconClock',
    },
  ]
}
