import type { DropdownItem } from '@/types/menu-dropdown'

const menuCustomerItems: DropdownItem[] = [
  {
    sortOrder: 1.5,
    id: 'messages',
    url: '/account/messages',
    label: 'Messages',
    isMdHidden: true,
    unread: false,
  },
  {
    sortOrder: 7,
    id: 'settings',
    url: '/account/settings',
    label: 'Account Settings',
  },
  {
    sortOrder: 8,
    id: 'logout',
    label: 'Log out',
    command: true,
  },
]

export const menuBrandItems: DropdownItem[] = [
  ...menuCustomerItems,
  {
    sortOrder: 1,
    id: 'marketplace',
    url: '/search/all',
    label: 'Marketplace',
    isMdHidden: true,
  },
  {
    sortOrder: 2,
    id: 'brand-orders',
    url: '/account/brand/orders',
    label: 'Orders',
    isMdHidden: true,
  },
  {
    sortOrder: 3,
    id: 'favorites',
    url: '/account/favorites',
    label: 'Favorites',
    isMdHidden: true,
  },
]

export const menuNotPublishedCreatorItems: DropdownItem[] = [
  ...menuCustomerItems,
  {
    sortOrder: 1,
    id: 'creator-resume',
    url: '/account/creator/new',
    label: "Resume Creator's Form",
  },
]

export const menuPublishedCreatorItems: DropdownItem[] = [
  ...menuCustomerItems,
  {
    sortOrder: 1,
    id: 'dashboard',
    url: '/account/dashboard',
    label: 'Dashboard',
    isMdHidden: true,
  },
  {
    sortOrder: 3,
    id: 'creator-orders',
    url: '/account/creator/orders',
    label: 'Orders',
    isMdHidden: true,
  },
  {
    sortOrder: 4,
    id: 'collabs',
    url: '/account/creator/collabs',
    label: 'Collabs',
    isMdHidden: true,
  },
  {
    sortOrder: 5,
    id: 'payments',
    url: '/account/creator/payments',
    label: 'Payments',
    isMdHidden: true,
  },
  {
    sortOrder: 6,
    id: 'creator-settings',
    url: '/account/creator/settings',
    label: 'Creator Settings',
  },
]
