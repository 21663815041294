import { useForm } from 'vee-validate'
import validationSchema from 'gig/validation-schema/create/details'
import type { UpdateGigData, GigResponse } from 'gig/types/create-gig'

export const useDetailsStore = defineStore('gigCreateDetailsStore', () => {
  interface initStoreValues {
    contentIsAbout: string[]
    contentType: string[]
    tags: string[]
  }

  const initialValues: initStoreValues = {
    contentIsAbout: [],
    contentType: [],
    tags: [],
  }
  const form = useForm({ validationSchema, initialValues })
  const [contentIsAbout] = form.useFieldModel(['contentIsAbout'])
  const [contentType] = form.useFieldModel(['contentType'])
  const [tags] = form.useFieldModel(['tags'])
  const { isSubmitted: isFormSubmitted } = useValidation(form.submitCount)
  const removeChip = (
    fieldName: 'contentIsAbout' | 'contentType' | 'tags',
    fieldValue: string[],
    chipName: string,
  ) => {
    const newValue = fieldValue.filter((item) => item !== chipName)
    form.setFieldValue(fieldName, newValue)
  }

  const isProcessing = ref(false)
  const { $notify } = useNuxtApp()
  const createGigStore = useGigCreateStore()
  const updateGig = async () => {
    if (!createGigStore.gigVersion) {
      return false
    }
    isProcessing.value = true
    const body: UpdateGigData = {
      version: createGigStore.gigVersion,
      actions: [
        {
          action: 'setAttributeInAllVariants',
          staged: false,
          name: 'content_type',
          value: toRaw(contentType.value),
        },
        {
          action: 'setAttributeInAllVariants',
          staged: false,
          name: 'tags',
          value: toRaw(tags.value),
        },
        {
          action: 'setAttributeInAllVariants',
          staged: false,
          name: 'content_is_about',
          value: toRaw(contentIsAbout.value),
        },
      ],
    }
    try {
      const { data, error } = await useApi<GigResponse>(
        `me/product/${createGigStore.gigId}`,
        {
          method: 'post',
          body,
        },
      )
      const gig = data.value
      if (error.value || !gig) {
        throw new Error(
          error.value?.message || 'Failed to save Collab changes.',
        )
      }
      $notify('Saved Collab changes')
      createGigStore.gigVersion = gig.version
      const pricingStore = usePricingStore()
      createGigStore.setValidationStatuses(gig)
      pricingStore.setVariantsData(gig)
      pricingStore.copyAdditionalAttributesFromResponse(gig)
      return true
    } catch (error) {
      $notify({
        text: (error as Error).message,
        severity: 'error',
      })
      return false
    } finally {
      isProcessing.value = false
    }
  }

  return {
    contentIsAbout,
    contentType,
    tags,
    form,
    isFormSubmitted,
    removeChip,
    isProcessing,
    updateGig,
  }
})
