export function fbInit() {
  const scriptsLocation = document.querySelector('script')
  if (document.querySelector('#facebook-jssdk')) {
    window.dispatchEvent(new Event('fb-sdk-ready'))
    return
  }
  const script = document.createElement('script')
  script.id = 'facebook-jssdk'
  script.src = '//connect.facebook.net/en_US/sdk.js'
  scriptsLocation?.parentNode?.insertBefore(script, scriptsLocation)

  script.addEventListener(
    'load',
    () => {
      window.fbAsyncInit = function onSDKInit() {
        const runtimeConfig = useRuntimeConfig()
        FB.init({
          appId: runtimeConfig.public.fbAppId,
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v15.0',
        })
        FB.AppEvents.logPageView()
        window.dispatchEvent(new Event('fb-sdk-ready'))
      }
    },
    { once: true },
  )
}

export function fbLogout() {
  FB.getLoginStatus(({ authResponse }) => {
    if (authResponse?.accessToken) {
      FB.logout()
    }
  })
}

export function fbInitAndLogout() {
  if (!window.FB) {
    fbInit()
    window.addEventListener('fb-sdk-ready', fbLogout, { once: true })
  } else {
    fbLogout()
  }
}
