import { skipHydrate } from 'pinia'
import type { NotificationProps } from '@/types/notification'

export const useNotificationsStore = defineStore('notificationsStore', () => {
  const notifications = ref<NotificationProps[]>([])
  const headerIsVisible = ref(true)
  const remove = (id: number) => {
    notifications.value = notifications.value.filter((item) => item.id !== id)
  }

  return {
    notifications: skipHydrate(notifications),
    headerIsVisible: skipHydrate(headerIsVisible),
    remove,
  }
})
