import { object, date, string, number } from 'yup'
const { lettersOnlyRule, emailRule, slugRule, phoneRuleCreator, genderRule } =
  validationRules()

export const personalInformationSchema = object({
  profilePicture: string().required(),
  username: string().required().max(16),
  firstName: lettersOnlyRule,
  lastName: lettersOnlyRule,
  profileUrl: slugRule,
  date: date()
    .required()
    .min(new Date(1900, 0, 1)),
  birthDay: number().required().min(1).max(31),
  birthMonth: number().required().min(1).max(12),
  birthYear: number()
    .required()
    .min(1900)
    .max(new Date().getFullYear() - 14),
  gender: genderRule,
})

export const professionalInformationSchema = object({
  desc: string().required().min(10).max(500),
  country: string()
    .required()
    .notOneOf(['UNDEFINED'], 'This is a required field'),
  state: string().when('country', {
    is: (country: string) => country === 'US',
    then: (schema) => schema.required(),
  }),
  city: string().required().nullable(),
  address: string().required(),
  postCode: string().required(),
})

export const paymentsSchema = object({
  email: emailRule.required(),
  phone: phoneRuleCreator,
})
