import validate from "/app/node_modules/.pnpm/nuxt@3.8.2_@types+node@20.10.3_eslint@8.55.0_typescript@5.2.2_vite@4.5.1_vue-tsc@1.8.15/node_modules/nuxt/dist/pages/runtime/validate.js";
import favourites_45global from "/app/middleware/favourites.global.ts";
import page_45view_45global from "/app/middleware/pageView.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.8.2_@types+node@20.10.3_eslint@8.55.0_typescript@5.2.2_vite@4.5.1_vue-tsc@1.8.15/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  favourites_45global,
  page_45view_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/middleware/auth.ts"),
  creator: () => import("/app/middleware/creator.ts")
}