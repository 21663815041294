import { packageNames } from 'gig/data/package-names'
import type { PackageData } from 'gig/types/gig-details'

export default (product: any) => {
  const variants = Array.from(product.variants)
  variants.unshift(product.masterVariant)

  const packages: PackageData[] = variants.map(
    (item: any): PackageData => ({
      id: item.id,
      sku: item?.sku,
      name:
        item.attributes.find((item: any) => item.name === 'variant_name')
          ?.value || packageNames[0],
      description:
        item.attributes.find((item: any) => item.name === 'variant_description')
          ?.value || '',
      postsNumber: item.attributes.find(
        (item: any) => item.name === 'posts_number',
      )?.value,
      revisions: item.attributes.find((item: any) => item.name === 'revisions')
        ?.value,
      deliveryTime: item.attributes.find(
        (item: any) => item.name === 'delivery_time',
      )?.value,
      contentCreation: item.attributes.find(
        (item: any) => item.name === 'content_creation',
      )?.value,
      post: item.attributes.find((item: any) => item.name === 'post')?.value,
      engagement: item.attributes.find(
        (item: any) => item.name === 'engagement',
      )?.value,
      price: item.prices?.[0]?.value,
      extraDelivery: item.attributes.find(
        (item: any) => item.name === 'extra_delivery',
      )?.value,
      extraDeliveryTime: item.attributes.find(
        (item: any) => item.name === 'extra_delivery_time',
      )?.value,
      extraDeliveryFee: item.attributes.find(
        (item: any) => item.name === 'extra_delivery_fee',
      )?.value,
      platforms:
        item.attributes.find((item: any) => item.name === 'extra_platforms')
          ?.value || [],
      licensingRights: item.attributes.find(
        (item: any) => item.name === 'licensing_rights',
      )?.value,
      contentType:
        item.attributes.find((item: any) => item.name === 'content_type')
          ?.value || [],
      tags:
        item.attributes.find((item: any) => item.name === 'tags')?.value || [],
      contentIsAbout:
        item.attributes.find((item: any) => item.name === 'content_is_about')
          ?.value || [],
      requirements:
        item.attributes.find((item: any) => item.name === 'requirements')
          ?.value || [],
      usBased: item.attributes.find((item: any) => item.name === 'us_based')
        ?.value,
    }),
  )
  return packages
}
