export const publicAttributes = [
  'follower_count',
  'follower_count_numeric',
  'creator_name',
  'creator_detail',
  'language',
  'location',
  'location_city',
  'location_state',
  'creator_age',
  'creator_gender',
  'creator_interests',
  'owner',
  'creator',
  'price_from',
  'popularity',
  'brand_assets',
  'creator_rating',
  'dspn',
  'dspn-car',
  'search_bio',
  'internal-tags',
]
