import type { LanguageCodes, Translation } from '@/types/translation'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      __: (source: Translation, lang: LanguageCodes = 'en-US'): string =>
        source[lang] || '',
    },
  }
})
