// Sentry module is not yet available for Nuxt 3 https://github.com/nuxt-community/sentry-module/issues/530
import * as Sentry from '@sentry/vue'
import type { Router } from 'vue-router'
import { defineNuxtPlugin, useRuntimeConfig } from '#app'

export default defineNuxtPlugin(({ vueApp, $router }) => {
  const { sentryDsn, sentryRelease, sentryEnv } = useRuntimeConfig().public

  if (!sentryDsn) {
    return
  }

  Sentry.init({
    app: vueApp,
    dsn: sentryDsn,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(
          $router as Router,
        ),
        tracePropagationTargets: ['brandedcontent.com/', /^\//],
      }),
    ],
    tracesSampleRate: 0.1,
    release: sentryRelease,
    environment: sentryEnv,
    ignoreErrors: [
      'You need to login first.',
      'Failed to initialize NumberFormat',
      'ResizeObserver loop limit exceeded',
      'window.webkit.messageHandlers',
    ],
    beforeSend(event, hint) {
      if (event.exception) {
        console.error(
          `[Exeption handled by Sentry]: (${hint.originalException})`,
          { event, hint },
        )
      }
      return event
    },
  })

  vueApp.mixin(
    Sentry.createTracingMixins({
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update'],
    }),
  )

  Sentry.attachErrorHandler(vueApp, {
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update'],
  })

  return {
    provide: {
      // sentrySetContext: (n, context) => Sentry.setContext(n, context),
      // sentrySetUser: (user) => Sentry.setUser(user),
      // sentrySetTag: (tagName, value) => Sentry.setTag(tagName, value),
      // sentryAddBreadcrumb: (breadcrumb) => Sentry.addBreadcrumb(breadcrumb),
      sentryCaptureException: Sentry.captureException,
    },
  }
})
