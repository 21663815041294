type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[]
}

declare const window: WindowWithDataLayer

export default defineNuxtRouteMiddleware((to, from) => {
  if (process.server) {
    return
  }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'Pageview',
    pagePath: to.path,
    pageTitle: to.name,
  })

  const routeStore = useRouteStore()
  routeStore.ssr = false
  routeStore.previousPagePath = from.path
  routeStore.previousPageQuery = from.query
})
