<script setup lang="ts">
import { breakpointsTailwind } from '@vueuse/core'
import menuGuestItems from '@/data/header/menu-guest.json'

const props = withDefaults(
  defineProps<{
    isBecomeCreatorLp?: boolean
  }>(),
  {
    isBecomeCreatorLp: false,
  },
)

const menuGuestItemsRef = ref(menuGuestItems)

const breakpoints = useBreakpoints(breakpointsTailwind)
const isMobile = breakpoints.smaller('md')

const mobileMenuItems = computed(() =>
  props.isBecomeCreatorLp
    ? menuGuestItemsRef.value.mobile.filter(
        (item) => item.url !== '/become-a-creator',
      )
    : menuGuestItemsRef.value.mobile,
)

const desktopMenuItems = computed(() =>
  props.isBecomeCreatorLp
    ? menuGuestItemsRef.value.desktop.filter(
        (item) => item.url !== '/become-a-creator',
      )
    : menuGuestItemsRef.value.desktop,
)
</script>

<template>
  <HeaderMenuGuestMobile
    class="flex md:hidden"
    :menu-items="mobileMenuItems"
    :is-mobile="isMobile"
  />
  <HeaderMenuGuestDesktop
    class="ml-auto hidden md:flex"
    :menu-items="desktopMenuItems"
    :is-mobile="isMobile"
  />
</template>
