import type { Step } from '@/types/steps'

const createAccountSteps: Step[] = [
  {
    label: 'Basic Information',
    to: '/account/creator/new/basic-information',
  },
  {
    label: 'Additional Information',
    to: '/account/creator/new/additional-information',
  },
  {
    label: 'Linked accounts',
    to: '/account/creator/new/linked-accounts',
  },
  {
    label: 'Payment',
    to: '/account/creator/new/payment',
  },
]
export default createAccountSteps
