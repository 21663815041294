import type { NuxtAppWithPinia } from '@/types/nuxtApp'
import type { NotificationProps } from '@/types/notification'
export default defineNuxtPlugin((nuxtApp) => {
  const { $pinia } = nuxtApp as NuxtAppWithPinia
  const store = useNotificationsStore($pinia)
  let id = 0

  return {
    provide: {
      notify: (notification: string | Omit<NotificationProps, 'id'>) => {
        if (typeof notification === 'string') {
          store.notifications.push({ id: ++id, text: notification })
        } else if (notification.text !== 'invalid_token') {
          store.notifications.push({ id: ++id, ...notification })
        }
      },
    },
  }
})
