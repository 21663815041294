<script setup lang="ts">
type AvatarSize =
  | 'small'
  | 'medium'
  | 'large'
  | 'extra-large'
  | 'extra-extra-large'

interface BaseAvatarProps {
  userName: string
  src?: string
  size?: AvatarSize
  alt?: string
}

const props = withDefaults(defineProps<BaseAvatarProps>(), {
  userName: 'User',
  src: undefined,
  size: 'medium',
  alt: 'Avatar image',
})
const avatarSize = ref(42)
const placeholderWidthClass = ref('w-[42px]')
const placeholderHeightClass = ref('h-[42px]')
const textSizeClass = ref('text-2xl')

switch (props.size) {
  case 'small':
    avatarSize.value = 30
    textSizeClass.value = 'text-base'
    placeholderWidthClass.value = 'w-[30px]'
    placeholderHeightClass.value = 'h-[30px]'
    break
  case 'medium':
    avatarSize.value = 42
    textSizeClass.value = 'text-2xl'
    placeholderWidthClass.value = 'w-[42px]'
    placeholderHeightClass.value = 'h-[42px]'
    break
  case 'large':
    avatarSize.value = 60
    textSizeClass.value = 'text-3xl'
    placeholderWidthClass.value = 'w-[60px]'
    placeholderHeightClass.value = 'h-[60px]'
    break
  case 'extra-large':
    avatarSize.value = 72
    textSizeClass.value = 'text-4xl'
    placeholderWidthClass.value = 'w-[72px]'
    placeholderHeightClass.value = 'h-[72px]'
    break
  case 'extra-extra-large':
    avatarSize.value = 128
    textSizeClass.value = 'text-4xl'
    placeholderWidthClass.value = 'w-[128px]'
    placeholderHeightClass.value = 'h-[128px]'
    break
}
</script>

<template>
  <NuxtPicture
    v-if="src"
    :width="avatarSize"
    :height="avatarSize"
    :src="src"
    :alt="alt"
    :sizes="`sm:${avatarSize * 2}`"
    :img-attrs="{
      class: 'rounded-full border border-gray-400',
    }"
  />
  <div
    v-else
    :class="[
      placeholderWidthClass,
      placeholderHeightClass,
      'flex items-center justify-center rounded-full bg-branded-colors',
    ]"
  >
    <span :class="[textSizeClass, 'text-white']">
      {{ userName.charAt(0).toUpperCase() }}
    </span>
  </div>
</template>
