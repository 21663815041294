export default [
  'IG Stories',
  'Animation',
  'Testimonials',
  'Product Shots',
  'Instagram Post',
  'Instagram Story',
  'Instagram Reel',
  'TikTok Feed',
  'TikTok Top View',
  'TikTok Testimonial',
  'TikTok Stop Motion',
  'TikTok Animation',
  'TikTok Narrative',
  'TikTok Tutorial',
  'YouTube Testimonial/Review',
  'YouTube Stop Motion',
  'YouTube Animation',
  'YouTube Narrative',
  'YouTube Tutorial',
  'Facebook News Feed',
  'Facebook Story',
  'Snapchat',
  'Pinterest',
  'Stop Motion Video',
  'Animation',
  'Email Marketing',
  'TV Marketing',
  'E-Commerce Marketing',
  'Professional Video',
]
