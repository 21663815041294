import type { LocationQuery } from 'vue-router'

export const useRouteStore = defineStore('routeStore', () => {
  const previousPagePath = ref<string | undefined>()
  const previousPageQuery = ref<LocationQuery | undefined>()
  const ssr = ref<boolean>(true)

  const isPreviousRouteGig = computed(() => {
    return !!previousPagePath.value?.includes('/collab/')
  })

  return {
    isPreviousRouteGig,
    previousPagePath,
    previousPageQuery,
    ssr,
  }
})
