import { useForm } from 'vee-validate'
import validationSchema from 'gig/validation-schema/create/description'
import type {
  CreateGigData,
  UpdateGigData,
  GigResponse,
} from 'gig/types/create-gig'

export const useDescriptionStore = defineStore(
  'gigCreateDescriptionStore',
  () => {
    interface initStoreValues {
      title: string
      description: string
    }
    const initialValues: initStoreValues = {
      title: '',
      description: '',
    }
    const form = useForm({ validationSchema, initialValues })
    const [title] = form.useFieldModel(['title'])
    const [description] = form.useFieldModel(['description'])
    const { isSubmitted: isFormSubmitted } = useValidation(form.submitCount)
    const url = ref('')
    const requirements = ref([
      'Thanks for ordering! Please provide me with any requirements for the Collab',
    ])
    const usBased = ref()

    const isProcessing = ref(false)
    const { $notify } = useNuxtApp()
    const createGigStore = useGigCreateStore()
    const pricingStore = usePricingStore()
    const creationRetryAttempts = ref(0)
    const createGig = async () => {
      try {
        isProcessing.value = true
        const body: CreateGigData = {
          name: {
            'en-US': title.value,
          },
          slug: {
            'en-US': url.value,
          },
          description: {
            'en-US': description.value,
          },
          masterVariant: {
            attributes: [
              {
                name: 'requirements',
                value: requirements.value,
              },
            ],
          },
          publish: false,
        }
        const { data, error } = await useApi<GigResponse>('me/gig', {
          method: 'post',
          body,
        })
        const gig = data.value
        if (error.value || !gig) {
          if (error.value?.message?.includes('duplicate value')) {
            const { newSlug } = suggestNewSlug(url.value)
            url.value = newSlug
            throw new Error('DuplicateField')
          } else {
            throw new Error(
              error.value?.message || 'Failed to create Collab draft.',
            )
          }
        }
        $notify('Created Collab draft')
        createGigStore.gigVersion = gig.version
        createGigStore.gigId = gig.id
        createGigStore.setValidationStatuses(gig)
        pricingStore.setVariantsData(gig)
        pricingStore.copyAdditionalAttributesFromResponse(gig)
        return true
      } catch (error) {
        if (!(error instanceof Error)) {
          return false
        }
        if (error.message === 'DuplicateField') {
          creationRetryAttempts.value++
          if (creationRetryAttempts.value <= 2) {
            // Retry gig creation with a newly generated slug - up to two times to avoid request loop
            const isSuccess = await createGig()
            if (isSuccess) {
              creationRetryAttempts.value = 0
              return true
            }
          } else {
            // Show notification and wait for another manual form submit
            $notify({
              text: 'Failed to create Collab draft. Please try again',
              severity: 'error',
            })
            return false
          }
        } else {
          $notify({
            text: error.message,
            severity: 'error',
          })
          return false
        }
      } finally {
        isProcessing.value = false
      }
    }
    const updateGig = async () => {
      if (!createGigStore.gigVersion) {
        return false
      }
      isProcessing.value = true
      const body: UpdateGigData = {
        version: createGigStore.gigVersion,
        actions: [
          {
            action: 'changeName',
            staged: false,
            name: {
              'en-US': title.value,
            },
          },
          {
            action: 'setDescription',
            staged: false,
            description: {
              'en-US': description.value,
            },
          },
        ],
      }
      try {
        const { data, error } = await useApi<GigResponse>(
          `me/product/${createGigStore.gigId}`,
          {
            method: 'post',
            body,
          },
        )
        const gig = data.value
        if (error.value || !gig) {
          throw new Error(
            error.value?.message || 'Failed to save Collab changes.',
          )
        }
        $notify('Saved Collab changes')
        createGigStore.gigVersion = gig.version
        return true
      } catch (error) {
        $notify({
          text: (error as Error).message,
          severity: 'error',
        })
        return false
      } finally {
        isProcessing.value = false
      }
    }

    return {
      title,
      description,
      form,
      isFormSubmitted,
      url,
      requirements,
      usBased,
      isProcessing,
      creationRetryAttempts,
      createGig,
      updateGig,
    }
  },
)
