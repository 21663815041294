import { useForm } from 'vee-validate'
import { skipHydrate } from 'pinia'
import validationSchema from 'gig/validation-schema/create/gallery'
import createGigSteps from 'gig/data/steps'
import {
  requiredAssetsNumber,
  requiredLogosNumber,
} from 'gig/data/media-requirements.json'
import type { GigResponse } from 'gig/types/create-gig'
import type { Asset } from '@/types/asset'

export const useGalleryStore = defineStore('gigCreateGalleryStore', () => {
  const createGigStore = useGigCreateStore()

  const initialValues: {
    assets: Asset[]
    brandIds: string[]
  } = { assets: [], brandIds: [] }
  const form = useForm({ validationSchema, initialValues })
  const [assets] = form.useFieldModel(['assets'])
  const [brandIds] = form.useFieldModel(['brandIds'])
  const { isSubmitted: isFormSubmitted } = useValidation(form.submitCount)
  const currentAssetsId = ref<(string | null)[]>([])
  const originalAssetsId = ref<string[]>([])
  const areAssetsValid = computed(() => {
    // In gallery grid the first 4 items need to be filled with uploaded assets. It doesn't matter if there are some more assets uploaded in further positions.
    const requiredAssets = currentAssetsId.value?.slice(0, requiredAssetsNumber)
    return (
      requiredAssets.length === requiredAssetsNumber &&
      requiredAssets?.every((asset) => asset !== null)
    )
  })
  const areLogosValid = computed(
    // form.errors.value.brandIds doesn't return proper value on load so I'm checking brandIds length instead
    () => brandIds.value.length >= requiredLogosNumber,
  )
  const isValid = computed(() => areAssetsValid.value && areLogosValid.value)

  const fetchGigAssets = async () => {
    try {
      const { data, error } = await useApi<GigResponse>(
        `/me/product/${createGigStore.gigId}`,
      )
      const gig = data.value

      if (error.value || !gig) {
        throw new Error(error.value?.message || 'No Collab data')
      }

      createGigStore.gigVersion = gig.version
      assets.value = gig.masterData.current.masterVariant.assets
      originalAssetsId.value = assets.value?.map((asset) => asset.id) || []
      brandIds.value =
        (gig.masterData?.current?.masterVariant?.attributes?.find(
          (item) => item.name === 'brand_assets',
        )?.value as string[] | undefined) || []
    } catch (error) {
      console.error('Failed to get updated media')
    }
  }

  const galleryStepIndex = createGigSteps('').findIndex(
    (step) => step.label === 'Gallery',
  )
  watch(isValid, (newVal) => {
    createGigStore.validationStatuses[galleryStepIndex] = newVal
  })

  return {
    assets,
    brandIds,
    form,
    isFormSubmitted,
    fetchGigAssets,
    currentAssetsId: skipHydrate(currentAssetsId),
    originalAssetsId: skipHydrate(originalAssetsId),
    areAssetsValid,
    isValid,
  }
})
