import { default as dashboardtcOlXFZQnJMeta } from "/app/modules/account/pages/account/dashboard.vue?macro=true";
import { default as personal_45informationLi71ksOQ8zMeta } from "/app/modules/account/pages/account/creator/new/personal-information.vue?macro=true";
import { default as professional_45informationzRwTgEI2TkMeta } from "/app/modules/account/pages/account/creator/new/professional-information.vue?macro=true";
import { default as linked_45accountstxqwQe0zUkMeta } from "/app/modules/account/pages/account/creator/new/linked-accounts.vue?macro=true";
import { default as paymentjrtDobGcEMMeta } from "/app/modules/account/pages/account/creator/new/payment.vue?macro=true";
import { default as newALttMcFtiQMeta } from "/app/modules/account/pages/account/creator/new.vue?macro=true";
import { default as accountVpxjxoSG5QMeta } from "/app/modules/account/pages/account/settings/account.vue?macro=true";
import { default as securityGg6Wg78lLfMeta } from "/app/modules/account/pages/account/settings/security.vue?macro=true";
import { default as settingsAfkUzb3zdiMeta } from "/app/modules/account/pages/account/settings.vue?macro=true";
import { default as indexM6WP1EvLttMeta } from "/app/modules/account/pages/account/brand/orders/index.vue?macro=true";
import { default as completed7FrdsXjtC4Meta } from "/app/modules/account/pages/account/brand/orders/completed.vue?macro=true";
import { default as orders10laYn56dSMeta } from "/app/modules/account/pages/account/brand/orders.vue?macro=true";
import { default as _91id_93vTWaQCL7XSMeta } from "/app/modules/account/pages/account/order/[id].vue?macro=true";
import { default as indexLU8YMzRcvaMeta } from "/app/modules/account/pages/account/creator/orders/index.vue?macro=true";
import { default as completedmnNTeRlJLYMeta } from "/app/modules/account/pages/account/creator/orders/completed.vue?macro=true";
import { default as ordersAFsknPFGqRMeta } from "/app/modules/account/pages/account/creator/orders.vue?macro=true";
import { default as activesj41k0wKPUMeta } from "/app/modules/account/pages/account/creator/collabs/active.vue?macro=true";
import { default as inactivexrrw2p4rwdMeta } from "/app/modules/account/pages/account/creator/collabs/inactive.vue?macro=true";
import { default as collabsAnaEf8A4DoMeta } from "/app/modules/account/pages/account/creator/collabs.vue?macro=true";
import { default as summaryBuJroiUjuZMeta } from "/app/modules/account/pages/account/creator/payments/summary.vue?macro=true";
import { default as detailHorE8X4SbyMeta } from "/app/modules/account/pages/account/creator/payments/detail.vue?macro=true";
import { default as paymentst19qKrVXBZMeta } from "/app/modules/account/pages/account/creator/payments.vue?macro=true";
import { default as _91_91id_93_93eINGMOsnqOMeta } from "/app/modules/account/pages/messages/[[id]].vue?macro=true";
import { default as personal_45informationWmnC7GTfFzMeta } from "/app/modules/account/pages/account/creator/settings/personal-information.vue?macro=true";
import { default as professional_45information2mDlU5kSmXMeta } from "/app/modules/account/pages/account/creator/settings/professional-information.vue?macro=true";
import { default as linked_45accountsBTXSEOQzYHMeta } from "/app/modules/account/pages/account/creator/settings/linked-accounts.vue?macro=true";
import { default as payment7cUkSZ33d1Meta } from "/app/modules/account/pages/account/creator/settings/payment.vue?macro=true";
import { default as settingsgcixikiX4IMeta } from "/app/modules/account/pages/account/creator/settings.vue?macro=true";
import { default as indexkN73iGO7HOMeta } from "/app/modules/account/pages/account/favorites/index.vue?macro=true";
import { default as collabsMuhLvFmGZIMeta } from "/app/modules/account/pages/account/favorites/collabs.vue?macro=true";
import { default as creators0f11EEHoGeMeta } from "/app/modules/account/pages/account/favorites/creators.vue?macro=true";
import { default as _91_91slug_93_93t12pHtuNgXMeta } from "/app/modules/profile/pages/profile/[[slug]].vue?macro=true";
import { default as _91slug_936Qto6M5e2AMeta } from "/app/modules/gig/pages/collab/[slug].vue?macro=true";
import { default as _91query_93jHZBGcJXQsMeta } from "/app/modules/gig/pages/search/[query].vue?macro=true";
import { default as descriptionQCeFeyagQYMeta } from "/app/modules/gig/pages/create-collab/[slug]/description.vue?macro=true";
import { default as detailsjxMENjBCp9Meta } from "/app/modules/gig/pages/create-collab/[slug]/details.vue?macro=true";
import { default as pricingN7joiS83UaMeta } from "/app/modules/gig/pages/create-collab/[slug]/pricing.vue?macro=true";
import { default as gallerysKn8I6vBDLMeta } from "/app/modules/gig/pages/create-collab/[slug]/gallery.vue?macro=true";
import { default as _91slug_93KgI3bmmvV1Meta } from "/app/modules/gig/pages/create-collab/[slug].vue?macro=true";
import { default as indexEj3JA93okUMeta } from "/app/modules/cms/pages/index.vue?macro=true";
import { default as become_45a_45creatoreNVgFk6UouMeta } from "/app/modules/cms/pages/become-a-creator.vue?macro=true";
import { default as privacy_45policylgDrKM5EQaMeta } from "/app/modules/cms/pages/privacy-policy.vue?macro=true";
import { default as terms_45of_45usenjvnM5Zs68Meta } from "/app/modules/cms/pages/terms-of-use.vue?macro=true";
import { default as index8c0UGZ6G8ZMeta } from "/app/modules/checkout/pages/checkout/index.vue?macro=true";
import { default as _91status_93EuLBAFaRlPMeta } from "/app/modules/checkout/pages/checkout/payment/[status].vue?macro=true";
export default [
  {
    name: dashboardtcOlXFZQnJMeta?.name ?? "account-dashboard-page",
    path: dashboardtcOlXFZQnJMeta?.path ?? "/account/dashboard",
    meta: dashboardtcOlXFZQnJMeta || {},
    alias: dashboardtcOlXFZQnJMeta?.alias || [],
    redirect: dashboardtcOlXFZQnJMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/dashboard.vue").then(m => m.default || m)
  },
  {
    name: newALttMcFtiQMeta?.name ?? "creator-new-page",
    path: newALttMcFtiQMeta?.path ?? "/account/creator/new",
    children: [
  {
    name: personal_45informationLi71ksOQ8zMeta?.name ?? "creator-new-personal-information-page",
    path: personal_45informationLi71ksOQ8zMeta?.path ?? "/account/creator/new/basic-information",
    meta: personal_45informationLi71ksOQ8zMeta || {},
    alias: personal_45informationLi71ksOQ8zMeta?.alias || [],
    redirect: personal_45informationLi71ksOQ8zMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/creator/new/personal-information.vue").then(m => m.default || m)
  },
  {
    name: professional_45informationzRwTgEI2TkMeta?.name ?? "creator-new-professional-information-page",
    path: professional_45informationzRwTgEI2TkMeta?.path ?? "/account/creator/new/additional-information",
    meta: professional_45informationzRwTgEI2TkMeta || {},
    alias: professional_45informationzRwTgEI2TkMeta?.alias || [],
    redirect: professional_45informationzRwTgEI2TkMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/creator/new/professional-information.vue").then(m => m.default || m)
  },
  {
    name: linked_45accountstxqwQe0zUkMeta?.name ?? "creator-new-linked-accounts-page",
    path: linked_45accountstxqwQe0zUkMeta?.path ?? "/account/creator/new/linked-accounts",
    meta: linked_45accountstxqwQe0zUkMeta || {},
    alias: linked_45accountstxqwQe0zUkMeta?.alias || [],
    redirect: linked_45accountstxqwQe0zUkMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/creator/new/linked-accounts.vue").then(m => m.default || m)
  },
  {
    name: paymentjrtDobGcEMMeta?.name ?? "creator-new-payment-page",
    path: paymentjrtDobGcEMMeta?.path ?? "/account/creator/new/payment",
    meta: paymentjrtDobGcEMMeta || {},
    alias: paymentjrtDobGcEMMeta?.alias || [],
    redirect: paymentjrtDobGcEMMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/creator/new/payment.vue").then(m => m.default || m)
  }
],
    meta: newALttMcFtiQMeta || {},
    alias: newALttMcFtiQMeta?.alias || [],
    redirect: newALttMcFtiQMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/creator/new.vue").then(m => m.default || m)
  },
  {
    name: settingsAfkUzb3zdiMeta?.name ?? "account-settings-page",
    path: settingsAfkUzb3zdiMeta?.path ?? "/account/settings",
    children: [
  {
    name: accountVpxjxoSG5QMeta?.name ?? "account-settings-account-page",
    path: accountVpxjxoSG5QMeta?.path ?? "/account/settings",
    meta: accountVpxjxoSG5QMeta || {},
    alias: accountVpxjxoSG5QMeta?.alias || [],
    redirect: accountVpxjxoSG5QMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/settings/account.vue").then(m => m.default || m)
  },
  {
    name: securityGg6Wg78lLfMeta?.name ?? "account-settings-security-page",
    path: securityGg6Wg78lLfMeta?.path ?? "/account/settings/security",
    meta: securityGg6Wg78lLfMeta || {},
    alias: securityGg6Wg78lLfMeta?.alias || [],
    redirect: securityGg6Wg78lLfMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/settings/security.vue").then(m => m.default || m)
  }
],
    meta: settingsAfkUzb3zdiMeta || {},
    alias: settingsAfkUzb3zdiMeta?.alias || [],
    redirect: settingsAfkUzb3zdiMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: orders10laYn56dSMeta?.name ?? "account-brand-orders-page",
    path: orders10laYn56dSMeta?.path ?? "/account/brand/orders",
    children: [
  {
    name: indexM6WP1EvLttMeta?.name ?? "account-brand-active-orders-page",
    path: indexM6WP1EvLttMeta?.path ?? "/account/brand/orders",
    meta: indexM6WP1EvLttMeta || {},
    alias: indexM6WP1EvLttMeta?.alias || [],
    redirect: indexM6WP1EvLttMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/brand/orders/index.vue").then(m => m.default || m)
  },
  {
    name: completed7FrdsXjtC4Meta?.name ?? "account-brand-completed-orders-page",
    path: completed7FrdsXjtC4Meta?.path ?? "/account/brand/orders/completed",
    meta: completed7FrdsXjtC4Meta || {},
    alias: completed7FrdsXjtC4Meta?.alias || [],
    redirect: completed7FrdsXjtC4Meta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/brand/orders/completed.vue").then(m => m.default || m)
  }
],
    meta: orders10laYn56dSMeta || {},
    alias: orders10laYn56dSMeta?.alias || [],
    redirect: orders10laYn56dSMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/brand/orders.vue").then(m => m.default || m)
  },
  {
    name: _91id_93vTWaQCL7XSMeta?.name ?? "account-order-details",
    path: _91id_93vTWaQCL7XSMeta?.path ?? "/account/order/:id",
    meta: _91id_93vTWaQCL7XSMeta || {},
    alias: _91id_93vTWaQCL7XSMeta?.alias || [],
    redirect: _91id_93vTWaQCL7XSMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/order/[id].vue").then(m => m.default || m)
  },
  {
    name: ordersAFsknPFGqRMeta?.name ?? "account-creator-orders-page",
    path: ordersAFsknPFGqRMeta?.path ?? "/account/creator/orders",
    children: [
  {
    name: indexLU8YMzRcvaMeta?.name ?? "account-creator-active-orders-page",
    path: indexLU8YMzRcvaMeta?.path ?? "/account/creator/orders",
    meta: indexLU8YMzRcvaMeta || {},
    alias: indexLU8YMzRcvaMeta?.alias || [],
    redirect: indexLU8YMzRcvaMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/creator/orders/index.vue").then(m => m.default || m)
  },
  {
    name: completedmnNTeRlJLYMeta?.name ?? "account-creator-completed-orders-page",
    path: completedmnNTeRlJLYMeta?.path ?? "/account/creator/orders/completed",
    meta: completedmnNTeRlJLYMeta || {},
    alias: completedmnNTeRlJLYMeta?.alias || [],
    redirect: completedmnNTeRlJLYMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/creator/orders/completed.vue").then(m => m.default || m)
  }
],
    meta: ordersAFsknPFGqRMeta || {},
    alias: ordersAFsknPFGqRMeta?.alias || [],
    redirect: ordersAFsknPFGqRMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/creator/orders.vue").then(m => m.default || m)
  },
  {
    name: collabsAnaEf8A4DoMeta?.name ?? "creator-collabs-page",
    path: collabsAnaEf8A4DoMeta?.path ?? "/account/creator/collabs",
    children: [
  {
    name: activesj41k0wKPUMeta?.name ?? "creator-collabs-active-page",
    path: activesj41k0wKPUMeta?.path ?? "/account/creator/collabs",
    meta: activesj41k0wKPUMeta || {},
    alias: activesj41k0wKPUMeta?.alias || [],
    redirect: activesj41k0wKPUMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/creator/collabs/active.vue").then(m => m.default || m)
  },
  {
    name: inactivexrrw2p4rwdMeta?.name ?? "creator-collabs-inactive-page",
    path: inactivexrrw2p4rwdMeta?.path ?? "/account/creator/collabs/inactive",
    meta: inactivexrrw2p4rwdMeta || {},
    alias: inactivexrrw2p4rwdMeta?.alias || [],
    redirect: inactivexrrw2p4rwdMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/creator/collabs/inactive.vue").then(m => m.default || m)
  }
],
    meta: collabsAnaEf8A4DoMeta || {},
    alias: collabsAnaEf8A4DoMeta?.alias || [],
    redirect: collabsAnaEf8A4DoMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/creator/collabs.vue").then(m => m.default || m)
  },
  {
    name: paymentst19qKrVXBZMeta?.name ?? "creator-payments-page",
    path: paymentst19qKrVXBZMeta?.path ?? "/account/creator/payments",
    children: [
  {
    name: summaryBuJroiUjuZMeta?.name ?? "creator-payments-summary-page",
    path: summaryBuJroiUjuZMeta?.path ?? "/account/creator/payments",
    meta: summaryBuJroiUjuZMeta || {},
    alias: summaryBuJroiUjuZMeta?.alias || [],
    redirect: summaryBuJroiUjuZMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/creator/payments/summary.vue").then(m => m.default || m)
  },
  {
    name: detailHorE8X4SbyMeta?.name ?? "creator-payments-detail-page",
    path: detailHorE8X4SbyMeta?.path ?? "/account/creator/payments/detail",
    meta: detailHorE8X4SbyMeta || {},
    alias: detailHorE8X4SbyMeta?.alias || [],
    redirect: detailHorE8X4SbyMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/creator/payments/detail.vue").then(m => m.default || m)
  }
],
    meta: paymentst19qKrVXBZMeta || {},
    alias: paymentst19qKrVXBZMeta?.alias || [],
    redirect: paymentst19qKrVXBZMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/creator/payments.vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_93eINGMOsnqOMeta?.name ?? "account-messages-page",
    path: _91_91id_93_93eINGMOsnqOMeta?.path ?? "/account/messages/:id?",
    meta: _91_91id_93_93eINGMOsnqOMeta || {},
    alias: _91_91id_93_93eINGMOsnqOMeta?.alias || [],
    redirect: _91_91id_93_93eINGMOsnqOMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/messages/[[id]].vue").then(m => m.default || m)
  },
  {
    name: settingsgcixikiX4IMeta?.name ?? "creator-settings-page",
    path: settingsgcixikiX4IMeta?.path ?? "/account/creator/settings",
    children: [
  {
    name: personal_45informationWmnC7GTfFzMeta?.name ?? "creator-settings-page",
    path: personal_45informationWmnC7GTfFzMeta?.path ?? "/account/creator/settings",
    meta: personal_45informationWmnC7GTfFzMeta || {},
    alias: personal_45informationWmnC7GTfFzMeta?.alias || [],
    redirect: personal_45informationWmnC7GTfFzMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/creator/settings/personal-information.vue").then(m => m.default || m)
  },
  {
    name: professional_45information2mDlU5kSmXMeta?.name ?? "creator-settings-professional-information-page",
    path: professional_45information2mDlU5kSmXMeta?.path ?? "/account/creator/settings/additional-information",
    meta: professional_45information2mDlU5kSmXMeta || {},
    alias: professional_45information2mDlU5kSmXMeta?.alias || [],
    redirect: professional_45information2mDlU5kSmXMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/creator/settings/professional-information.vue").then(m => m.default || m)
  },
  {
    name: linked_45accountsBTXSEOQzYHMeta?.name ?? "creator-settings-linked-accounts-page",
    path: linked_45accountsBTXSEOQzYHMeta?.path ?? "/account/creator/settings/linked-accounts",
    meta: linked_45accountsBTXSEOQzYHMeta || {},
    alias: linked_45accountsBTXSEOQzYHMeta?.alias || [],
    redirect: linked_45accountsBTXSEOQzYHMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/creator/settings/linked-accounts.vue").then(m => m.default || m)
  },
  {
    name: payment7cUkSZ33d1Meta?.name ?? "creator-settings-payment-page",
    path: payment7cUkSZ33d1Meta?.path ?? "/account/creator/settings/payment",
    meta: payment7cUkSZ33d1Meta || {},
    alias: payment7cUkSZ33d1Meta?.alias || [],
    redirect: payment7cUkSZ33d1Meta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/creator/settings/payment.vue").then(m => m.default || m)
  }
],
    meta: settingsgcixikiX4IMeta || {},
    alias: settingsgcixikiX4IMeta?.alias || [],
    redirect: settingsgcixikiX4IMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/creator/settings.vue").then(m => m.default || m)
  },
  {
    name: indexkN73iGO7HOMeta?.name ?? "account-favorites",
    path: indexkN73iGO7HOMeta?.path ?? "/account/favorites",
    meta: indexkN73iGO7HOMeta || {},
    alias: indexkN73iGO7HOMeta?.alias || [],
    redirect: indexkN73iGO7HOMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: collabsMuhLvFmGZIMeta?.name ?? "account-favorites-collabs",
    path: collabsMuhLvFmGZIMeta?.path ?? "/account/favorites/collabs",
    meta: collabsMuhLvFmGZIMeta || {},
    alias: collabsMuhLvFmGZIMeta?.alias || [],
    redirect: collabsMuhLvFmGZIMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/favorites/collabs.vue").then(m => m.default || m)
  },
  {
    name: creators0f11EEHoGeMeta?.name ?? "account-favorites-creators",
    path: creators0f11EEHoGeMeta?.path ?? "/account/favorites/creators",
    meta: creators0f11EEHoGeMeta || {},
    alias: creators0f11EEHoGeMeta?.alias || [],
    redirect: creators0f11EEHoGeMeta?.redirect || undefined,
    component: () => import("/app/modules/account/pages/account/favorites/creators.vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93t12pHtuNgXMeta?.name ?? "profile-page",
    path: _91_91slug_93_93t12pHtuNgXMeta?.path ?? "/profile/:slug?",
    meta: _91_91slug_93_93t12pHtuNgXMeta || {},
    alias: _91_91slug_93_93t12pHtuNgXMeta?.alias || [],
    redirect: _91_91slug_93_93t12pHtuNgXMeta?.redirect || undefined,
    component: () => import("/app/modules/profile/pages/profile/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936Qto6M5e2AMeta?.name ?? "collab-page",
    path: _91slug_936Qto6M5e2AMeta?.path ?? "/collab/:slug",
    meta: _91slug_936Qto6M5e2AMeta || {},
    alias: _91slug_936Qto6M5e2AMeta?.alias || [],
    redirect: _91slug_936Qto6M5e2AMeta?.redirect || undefined,
    component: () => import("/app/modules/gig/pages/collab/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91query_93jHZBGcJXQsMeta?.name ?? "collabs-search-page",
    path: _91query_93jHZBGcJXQsMeta?.path ?? "/search/:query",
    meta: _91query_93jHZBGcJXQsMeta || {},
    alias: _91query_93jHZBGcJXQsMeta?.alias || [],
    redirect: _91query_93jHZBGcJXQsMeta?.redirect || undefined,
    component: () => import("/app/modules/gig/pages/search/[query].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KgI3bmmvV1Meta?.name ?? "create-collab-page",
    path: _91slug_93KgI3bmmvV1Meta?.path ?? "/create-collab/:slug",
    children: [
  {
    name: descriptionQCeFeyagQYMeta?.name ?? "create-collab-description-page",
    path: descriptionQCeFeyagQYMeta?.path ?? "/create-collab/:slug/description",
    meta: descriptionQCeFeyagQYMeta || {},
    alias: descriptionQCeFeyagQYMeta?.alias || [],
    redirect: descriptionQCeFeyagQYMeta?.redirect || undefined,
    component: () => import("/app/modules/gig/pages/create-collab/[slug]/description.vue").then(m => m.default || m)
  },
  {
    name: detailsjxMENjBCp9Meta?.name ?? "create-collab-details-page",
    path: detailsjxMENjBCp9Meta?.path ?? "/create-collab/:slug/details",
    meta: detailsjxMENjBCp9Meta || {},
    alias: detailsjxMENjBCp9Meta?.alias || [],
    redirect: detailsjxMENjBCp9Meta?.redirect || undefined,
    component: () => import("/app/modules/gig/pages/create-collab/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: pricingN7joiS83UaMeta?.name ?? "create-collab-pricing-page",
    path: pricingN7joiS83UaMeta?.path ?? "/create-collab/:slug/pricing",
    meta: pricingN7joiS83UaMeta || {},
    alias: pricingN7joiS83UaMeta?.alias || [],
    redirect: pricingN7joiS83UaMeta?.redirect || undefined,
    component: () => import("/app/modules/gig/pages/create-collab/[slug]/pricing.vue").then(m => m.default || m)
  },
  {
    name: gallerysKn8I6vBDLMeta?.name ?? "create-collab-gallery-page",
    path: gallerysKn8I6vBDLMeta?.path ?? "/create-collab/:slug/gallery",
    meta: gallerysKn8I6vBDLMeta || {},
    alias: gallerysKn8I6vBDLMeta?.alias || [],
    redirect: gallerysKn8I6vBDLMeta?.redirect || undefined,
    component: () => import("/app/modules/gig/pages/create-collab/[slug]/gallery.vue").then(m => m.default || m)
  }
],
    meta: _91slug_93KgI3bmmvV1Meta || {},
    alias: _91slug_93KgI3bmmvV1Meta?.alias || [],
    redirect: _91slug_93KgI3bmmvV1Meta?.redirect || undefined,
    component: () => import("/app/modules/gig/pages/create-collab/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexEj3JA93okUMeta?.name ?? "home-page",
    path: indexEj3JA93okUMeta?.path ?? "/",
    meta: indexEj3JA93okUMeta || {},
    alias: indexEj3JA93okUMeta?.alias || [],
    redirect: indexEj3JA93okUMeta?.redirect || undefined,
    component: () => import("/app/modules/cms/pages/index.vue").then(m => m.default || m)
  },
  {
    name: become_45a_45creatoreNVgFk6UouMeta?.name ?? "become-a-creator-page",
    path: become_45a_45creatoreNVgFk6UouMeta?.path ?? "/become-a-creator",
    meta: become_45a_45creatoreNVgFk6UouMeta || {},
    alias: become_45a_45creatoreNVgFk6UouMeta?.alias || [],
    redirect: become_45a_45creatoreNVgFk6UouMeta?.redirect || undefined,
    component: () => import("/app/modules/cms/pages/become-a-creator.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policylgDrKM5EQaMeta?.name ?? "privacy-policy",
    path: privacy_45policylgDrKM5EQaMeta?.path ?? "/privacy-policy",
    meta: privacy_45policylgDrKM5EQaMeta || {},
    alias: privacy_45policylgDrKM5EQaMeta?.alias || [],
    redirect: privacy_45policylgDrKM5EQaMeta?.redirect || undefined,
    component: () => import("/app/modules/cms/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45usenjvnM5Zs68Meta?.name ?? "terms-of-use",
    path: terms_45of_45usenjvnM5Zs68Meta?.path ?? "/terms-of-use",
    meta: terms_45of_45usenjvnM5Zs68Meta || {},
    alias: terms_45of_45usenjvnM5Zs68Meta?.alias || [],
    redirect: terms_45of_45usenjvnM5Zs68Meta?.redirect || undefined,
    component: () => import("/app/modules/cms/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: index8c0UGZ6G8ZMeta?.name ?? "checkout-page",
    path: index8c0UGZ6G8ZMeta?.path ?? "/checkout",
    meta: index8c0UGZ6G8ZMeta || {},
    alias: index8c0UGZ6G8ZMeta?.alias || [],
    redirect: index8c0UGZ6G8ZMeta?.redirect || undefined,
    component: () => import("/app/modules/checkout/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: _91status_93EuLBAFaRlPMeta?.name ?? "checkout-payment-status-page",
    path: _91status_93EuLBAFaRlPMeta?.path ?? "/checkout/payment/:status",
    meta: _91status_93EuLBAFaRlPMeta || {},
    alias: _91status_93EuLBAFaRlPMeta?.alias || [],
    redirect: _91status_93EuLBAFaRlPMeta?.redirect || undefined,
    component: () => import("/app/modules/checkout/pages/checkout/payment/[status].vue").then(m => m.default || m)
  }
]