import type { User, Session } from 'talkjs/types/talk.types'
import { storeToRefs } from 'pinia'
import type { ImageModifiers } from '@nuxt/image'

export default defineNuxtPlugin(async () => {
  let user: User | null = null
  let session: Session | null = null

  const { chatApiKey } = useRuntimeConfig().public
  const { customer } = storeToRefs(useCustomerStore())
  const { isLoggedIn } = storeToRefs(useAuthStore())
  const { fetchInbox, fetchUnreads } = useChatStore()
  const initChat = async () => {
    if (!isLoggedIn.value) {
      return
    }

    const { data } = await useApi<User>('/me/chat')
    const meChatUser = data.value

    if (
      !meChatUser ||
      !customer.value?.id ||
      meChatUser.id !== customer.value?.id ||
      user ||
      session
    ) {
      return
    }

    const { default: Talk } = await import('talkjs')
    const $img = useImage()

    const getUserPhotoUrl = (size: number): string => {
      const modifiers: Partial<ImageModifiers> = {
        width: size,
        height: size,
      }
      const imgUrl = useProfilePicture(meChatUser.id).value

      return imgUrl
        ? $img(imgUrl, modifiers, {
            preset: 'base',
          })
        : ''
    }

    await Talk.ready.then(() => {
      user = new Talk.User({
        id: meChatUser.id,
        name: meChatUser.name.trim() || customer.value?.middleName || '',
        email: meChatUser.email?.[0],
        photoUrl: getUserPhotoUrl(50),
        role: 'default',
        custom: {
          x2PhotoUrl: getUserPhotoUrl(100),
          x3PhotoUrl: getUserPhotoUrl(150),
          rawPhotoUrl: useProfilePicture(meChatUser.id).value || '',
        },
      })

      session = new Talk.Session({
        appId: chatApiKey,
        me: user,
      })

      fetchInbox(meChatUser.id)
      session?.onMessage(() => fetchInbox(meChatUser.id))
      session?.unreads.onChange(fetchUnreads)
    })
  }

  // plugin entry point
  try {
    if (customer.value?.id) {
      await initChat()
    } else {
      watchOnce(customer, () => initChat())
    }
  } catch (err: any) {
    console.warn(`Chat is broken. ${err?.message || ''}`)
  }

  return {
    provide: {
      getChat: () => ({
        user,
        session,
        login: initChat,
        logout: () => {
          session?.destroy()
          user = null
          session = null
        },
      }),
    },
  }
})
