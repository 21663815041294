import { useForm } from 'vee-validate'
import type {
  AddressAction,
  EditAddressAction,
  Action,
} from 'account/types/actions'
import { personalInformationSchema } from 'account/validation-schema/creator'
import { useGenderUpdateAction } from 'account/composables/useGenderUpdateAction'
import { getAgeRange } from 'account/utils/get-age'

export const useEditPersonalInformationStore = defineStore(
  'creatorEditPersonalInformationStore',
  () => {
    const customerStore = useCustomerStore()
    const creatorStore = useCreatorStore()

    const getSavedValues = () => {
      const date = customerStore.customer?.dateOfBirth
        ? new Date(customerStore.customer.dateOfBirth)
        : undefined
      const customerAddress = customerStore.customer?.addresses[0]
      const attrs =
        creatorStore.creator?.masterData?.current?.masterVariant?.attributes
      const city =
        attrs?.find((attr) => attr.name === 'location_city')?.value || null
      const country =
        attrs?.find((attr) => attr.name === 'location_country')?.value || null

      return {
        profilePicture: customerStore.profilePicture,
        username: creatorStore.creator?.masterData?.current?.name['en-US'],
        firstName: customerAddress?.firstName,
        lastName: customerAddress?.lastName,
        profileUrl: creatorStore.creator?.masterData?.current?.slug['en-US'],
        date,
        birthDay: date
          ? parseInt(useDateFormat(date, 'DD').value, 10)
          : undefined,
        birthMonth: date
          ? parseInt(useDateFormat(date, 'MM').value, 10)
          : undefined,
        birthYear: date
          ? parseInt(useDateFormat(date, 'YYYY').value, 10)
          : undefined,
        gender: customerStore.customer?.custom?.fields?.gender || '',
        desc: creatorStore.creator?.masterData?.current?.description?.['en-US'],
        city,
        country,
        address: customerAddress?.streetName,
        apartment: customerAddress?.apartment,
        postCode: customerAddress?.postalCode,
      }
    }

    const form = useForm({
      validationSchema: personalInformationSchema,
      initialValues: getSavedValues(),
    })

    const [profilePicture] = form.useFieldModel(['profilePicture'])
    const [username] = form.useFieldModel(['username'])
    const [firstName] = form.useFieldModel(['firstName'])
    const [lastName] = form.useFieldModel(['lastName'])
    const [profileUrl] = form.useFieldModel(['profileUrl'])
    const [date] = form.useFieldModel(['date'])
    const [birthDay] = form.useFieldModel(['birthDay'])
    const [birthMonth] = form.useFieldModel(['birthMonth'])
    const [birthYear] = form.useFieldModel(['birthYear'])
    const [gender] = form.useFieldModel(['gender'])

    // removed from the form, required in backend
    const [desc] = form.useFieldModel(['desc'])
    // removed from the form, required in backend for firstName, lastName handling (part of address)
    const [country] = form.useFieldModel(['country'])
    const [city] = form.useFieldModel(['city'])
    const [address] = form.useFieldModel(['address'])
    const [apartment] = form.useFieldModel(['apartment'])
    const [postCode] = form.useFieldModel(['postCode'])

    const addressId = computed(() => customerStore?.customer?.addresses[0]?.id)
    const setAddressStructure = () => {
      if (addressId.value) {
        return reactive({
          action: 'changeAddress',
          addressId: addressId.value as string,
          address: {
            firstName: firstName.value as string,
            lastName: lastName.value as string,
            country: (country.value as string) || 'UNDEFINED',
            city: city.value as string,
            streetName: address.value as string,
            apartment: (apartment.value || '') as string,
            postalCode: postCode.value as string,
          },
        })
      } else {
        return reactive({
          action: 'addAddress',
          address: {
            firstName: firstName.value as string,
            lastName: lastName.value as string,
            country: country.value as string,
            city: city.value,
            streetName: address.value as string,
            apartment: (apartment.value || '') as string,
            postalCode: postCode.value as string,
          },
        })
      }
    }
    const { isSubmitted: isFormSubmitted } = useValidation(form.submitCount)

    const updateCustomerData = async () => {
      const actions: Action[] = [
        setAddressStructure() as AddressAction | EditAddressAction,
        {
          action: 'setDateOfBirth',
          dateOfBirth: useDateFormat(date.value as Date, 'YYYY-MM-DD').value,
        },
        {
          action: 'setMiddleName',
          middleName: username.value as string,
        },
      ]
      const genderAction = useGenderUpdateAction(gender.value as string)
      if (genderAction) {
        actions.push(genderAction)
      }
      return await customerStore.updateAccount(actions, {
        notify: true,
        prefetchData: true,
      })
    }
    const updateCreatorProfile = async () => {
      return await creatorStore.updateProfile(
        [
          {
            action: 'changeName',
            staged: false,
            name: {
              'en-US': username.value as string,
            },
          },
          {
            action: 'changeSlug',
            staged: false,
            slug: {
              'en-US': profileUrl.value as string,
            },
          },
          {
            action: 'setDescription',
            staged: false,
            description: {
              'en-US': desc.value as string,
            },
          },
          // Public attributes used for gigs filtering
          {
            action: 'setAttributeInAllVariants',
            staged: false,
            name: 'creator_age',
            value: getAgeRange(date.value as Date) || '',
          },
          {
            action: 'setAttributeInAllVariants',
            staged: false,
            name: 'creator_gender',
            value: gender.value || 'other',
          },
        ],
        {
          prefetchData: true,
        },
      )
    }
    const sendPersonalInformation = async () => {
      const isProfilePictureUpdated = await customerStore.updateProfilePicture(
        profilePicture.value as string | File,
      )
      const isCustomerUpdated = await updateCustomerData()
      const isCreatorUpdated = await updateCreatorProfile()
      if (isCreatorUpdated) {
        form.resetForm({ values: getSavedValues() })
      }

      const statuses = [
        isProfilePictureUpdated,
        isCustomerUpdated,
        isCreatorUpdated,
      ]
      const { $notify } = useNuxtApp()
      if (statuses.every((el) => el)) {
        $notify('Basic information updated successfully')
        return true
      } else {
        $notify({
          text: 'Failed to update creator data',
          severity: 'error',
        })
        return false
      }
    }

    return {
      getSavedValues,
      profilePicture,
      username,
      firstName,
      lastName,
      profileUrl,
      date,
      birthDay,
      birthMonth,
      birthYear,
      gender,
      desc,
      country,
      city,
      address,
      apartment,
      postCode,
      form,
      isFormSubmitted,
      sendPersonalInformation,
    }
  },
)
