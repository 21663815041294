import { useForm } from 'vee-validate'
import { personalInformationSchema } from 'account/validation-schema/creator'
import type { Action } from 'account/types/actions'
import { useGenderUpdateAction } from 'account/composables/useGenderUpdateAction'
import { getAgeRange } from 'account/utils/get-age'

export const useNewPersonalInformationStore = defineStore(
  'creatorNewPersonalInformationStore',
  () => {
    const form = useForm({
      validationSchema: personalInformationSchema,
      // TODO: use toTypedSchema from upgraded vee-validate (#98342)
      initialValues: {
        profilePicture: undefined,
        username: '',
        firstName: '',
        lastName: '',
        profileUrl: '',
        date: '',
        birthDay: 0,
        birthMonth: 0,
        birthYear: 0,
        gender: undefined,
        desc: '',
        country: 'UNDEFINED',
        city: '',
        address: '',
        apartment: '',
        postCode: '',
      },
    })

    const [profilePicture] = form.useFieldModel(['profilePicture'])
    const [username] = form.useFieldModel(['username'])
    const [firstName] = form.useFieldModel(['firstName'])
    const [lastName] = form.useFieldModel(['lastName'])
    const [profileUrl] = form.useFieldModel(['profileUrl'])
    const [date] = form.useFieldModel(['date'])
    const [birthDay] = form.useFieldModel(['birthDay'])
    const [birthMonth] = form.useFieldModel(['birthMonth'])
    const [birthYear] = form.useFieldModel(['birthYear'])
    const [gender] = form.useFieldModel(['gender'])

    // removed from the form, required in backend
    const [desc] = form.useFieldModel(['desc'])
    // removed from the form, required in backend for firstName, lastName handling
    const [country] = form.useFieldModel(['country'])
    const [city] = form.useFieldModel(['city'])
    const [address] = form.useFieldModel(['address'])
    const [apartment] = form.useFieldModel(['apartment'])
    const [postCode] = form.useFieldModel(['postCode'])

    const customerStore = useCustomerStore()
    const creatorStore = useCreatorStore()
    const { isSubmitted: isFormSubmitted } = useValidation(form.submitCount)

    const createCreatorProfile = async () => {
      return await creatorStore.createProfile({
        name: {
          'en-US': username.value,
        },
        slug: {
          'en-US': profileUrl.value,
        },
        description: {
          'en-US': desc.value,
        },
        masterVariant: {
          // Public attributes used for gigs filtering
          attributes: [
            {
              name: 'creator_age',
              value: getAgeRange(date.value as unknown as Date) || '',
            },
            {
              name: 'creator_gender',
              value: (gender.value as string | undefined) || 'other',
            },
          ],
        },
      })
    }
    const updateCustomerData = async () => {
      const actions: Action[] = [
        {
          action: 'addAddress',
          address: {
            firstName: firstName.value,
            lastName: lastName.value,
            country: country.value,
            city: city.value,
            streetName: address.value,
            apartment: apartment.value || '',
            postalCode: postCode.value,
          },
        },
        {
          action: 'setDateOfBirth',
          dateOfBirth: useDateFormat(date.value, 'YYYY-MM-DD').value,
        },
        {
          action: 'setMiddleName',
          middleName: username.value,
        },
      ]
      const genderAction = useGenderUpdateAction(gender.value)
      if (genderAction) {
        actions.push(genderAction)
      }
      return await customerStore.updateAccount(actions, {
        notify: false,
        prefetchData: true,
      })
    }
    const sendPersonalInformation = async () => {
      const isProfilePictureUploaded = await customerStore.updateProfilePicture(
        profilePicture.value,
      )
      let isCreatorCreated,
        errorMessage

        // first try
      ;({ success: isCreatorCreated, errorMessage } =
        await createCreatorProfile())
      // second try
      if (errorMessage && errorMessage === 'DuplicateField') {
        ;({ success: isCreatorCreated, errorMessage } =
          await createCreatorProfile())
      }
      if (!isCreatorCreated) {
        return false
      }
      await customerStore.fetchCustomerData() // Update customer version number
      const isCustomerUpdated = await updateCustomerData()
      return isProfilePictureUploaded && isCreatorCreated && isCustomerUpdated
    }

    return {
      profilePicture,
      username,
      firstName,
      lastName,
      profileUrl,
      date,
      birthDay,
      birthMonth,
      birthYear,
      gender,
      desc,
      country,
      city,
      address,
      apartment,
      postCode,
      form,
      isFormSubmitted,
      sendPersonalInformation,
    }
  },
)
