<script setup lang="ts">
import type { Ref } from 'vue'
import type { MaybeElementRef, MaybeElement } from '@vueuse/core'
import type { HeaderMenuGuestItemProps } from './MenuGuestItem.vue'

export interface MenuGuestMobileProps {
  menuItems: HeaderMenuGuestItemProps[]
  isMobile: boolean
}

const props = defineProps<MenuGuestMobileProps>()
const isMobileRef = toRef(props, 'isMobile')
const isMenuOpen = ref(false)
const showAuth = ref(true)
const menuButton: MaybeElementRef<MaybeElement> | Ref = ref()
const authDialogRef: MaybeElementRef<MaybeElement> | Ref = ref()
const menuDropdown = ref<HTMLElement>()
let unregisterListener: (() => void) | undefined

const closeMenu = () => {
  isMenuOpen.value = false
  menuButton.value?.$el?.focus()
}

const closeMenuCallback = () => {
  if (isMenuOpen.value && !authDialogRef.value?.authDialogDropdownRef.visible) {
    closeMenu()
  }
}

if (props.isMobile) {
  unregisterListener = onClickOutside(menuDropdown, closeMenuCallback, {
    ignore: [menuButton],
  })
}

watch(isMobileRef, () => {
  if (!isMobileRef.value) {
    if (unregisterListener) {
      unregisterListener()
      unregisterListener = undefined
    }
  } else if (!unregisterListener) {
    unregisterListener = onClickOutside(menuDropdown, closeMenuCallback, {
      ignore: [menuButton],
    })
  }

  showAuth.value = isMobileRef.value
})

onMounted(() => {
  showAuth.value = isMobileRef.value
})
</script>

<template>
  <div class="block">
    <Button
      ref="menuButton"
      type="button"
      :class="[
        { 'border border-gray-400 shadow-around': isMenuOpen },
        { 'border-0': !isMenuOpen },
      ]"
      class="guest-menu-trigger w-[42px] rounded-full bg-white p-3 text-gray-100"
      aria-haspopup="menu"
      :aria-expanded="isMenuOpen"
      label="Toggle Menu"
      @click="isMenuOpen = !isMenuOpen"
      @keydown.escape="closeMenu"
    >
      <i
        v-if="!isMenuOpen"
        class="pi pi-bars"
        aria-hidden="true"
      />
      <i
        v-else
        class="pi pi-times"
        aria-hidden="true"
      />
    </Button>
    <ul
      ref="menuDropdown"
      :class="[
        { hidden: !isMenuOpen },
        'absolute left-2 right-2 top-[72px] z-50 rounded-md bg-white shadow-around shadow-gray-400',
      ]"
      @keydown.escape="closeMenu"
    >
      <li>
        <LazyAuthDialog
          v-if="showAuth"
          ref="authDialogRef"
        />
      </li>
      <HeaderMenuGuestItem
        v-for="{ url, label, hasButtonLookMd } in menuItems"
        :key="`${url}-mobile`"
        :url="url"
        :label="label"
        :has-button-look-md="hasButtonLookMd"
      />
    </ul>
  </div>
</template>
