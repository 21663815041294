export enum EContent {
  SIGN_IN,
  SIGN_UP_FIRST_BRAND,
  SIGN_UP_SECOND_BRAND,
  SIGN_UP_FIRST_CREATOR,
  SIGN_UP_SECOND_CREATOR,
  SIGN_UP_CONFIRM,
  RECOVER_PASSWORD,
  RECOVER_PASSWORD_CONFIRM,
  SET_NEW_PASSWORD,
  SIGN_UP_SUCCESS,
}
