import { skipHydrate } from 'pinia'
import { packageNames } from 'gig/data/package-names'
import type { GigResponse } from 'gig/types/create-gig'

export const useGigCreateStore = defineStore('gigCreateStore', () => {
  const descriptionStore = useDescriptionStore()
  const detailsStore = useDetailsStore()
  const pricingStore = usePricingStore()
  const galleryStore = useGalleryStore()
  const validationStatuses = ref<[boolean, boolean, boolean, boolean]>([
    false,
    false,
    false,
    false,
  ])
  const gigVersion = ref<number | undefined>()
  const gigId = ref<string | undefined>()
  const isCreateNewGigPage = ref(true)

  const isGigCreated = computed(() => !!gigVersion.value)
  const resetSteps = () => {
    descriptionStore.$reset()
    detailsStore.$reset()
    galleryStore.$reset()
    pricingStore.$reset()
    pricingStore.resetPackageStores()
  }
  const fetchGigData = async (
    id: string,
    options: {
      setOnlyValidationStatuses: boolean
    } = { setOnlyValidationStatuses: false },
  ) => {
    const { $priceNumber, $notify } = useNuxtApp()

    try {
      const { data, error } = await useApi<GigResponse>(`/me/product/${id}`)

      const gig = data.value

      if (error.value || !gig) {
        throw new Error(error.value?.message || 'No Collab data found.')
      }

      const gigObjectData = gig.masterData.current
      // Gallery step data has to be set before calling setValidationStatuses
      const assetsIds = gigObjectData.masterVariant.assets.map(
        (asset) => asset.id,
      )
      galleryStore.currentAssetsId = [...assetsIds]
      galleryStore.originalAssetsId = [...assetsIds]
      galleryStore.assets = gigObjectData.masterVariant.assets
      galleryStore.brandIds =
        (gigObjectData.masterVariant?.attributes?.find(
          (item) => item.name === 'brand_assets',
        )?.value as string[] | undefined) || []

      setValidationStatuses(gig)

      if (options.setOnlyValidationStatuses) {
        return
      }

      const packages = usePackages(gigObjectData)
      gigId.value = gig.id
      gigVersion.value = gig.version
      descriptionStore.title = gigObjectData.name['en-US']
      descriptionStore.description = gigObjectData.description?.['en-US'] || ''
      descriptionStore.url = gigObjectData.slug['en-US']
      descriptionStore.requirements = packages[0].requirements
      descriptionStore.usBased =
        packages[0].usBased !== null ? packages[0].usBased : null
      detailsStore.contentType = packages[0].contentType
      detailsStore.tags = packages[0].tags
      detailsStore.contentIsAbout = packages[0].contentIsAbout

      pricingStore.updateFieldInAllPackages(
        'offerPackages',
        pricingStore.offerPackages,
      )
      packages.forEach((pricingPackage, index) => {
        const packageStore = usePackageStore(pricingPackage.name)

        packageStore.isMasterVariant = index === 0
        packageStore.isEnabled = true
        packageStore.id = pricingPackage.id
        packageStore.sku = pricingPackage.sku
        packageStore.description = pricingPackage.description || ''
        packageStore.revisions = pricingPackage.revisions
        packageStore.deliveryTime = pricingPackage.deliveryTime
        pricingPackage.engagement &&
          packageStore.extraOptions.push('engagement')
        pricingPackage.post && packageStore.extraOptions.push('post')
        pricingPackage.contentCreation &&
          packageStore.extraOptions.push('content_creation')
        packageStore.price = pricingPackage.price
          ? $priceNumber(pricingPackage.price)
          : undefined
        packageStore.extraFastDeliveryTime = pricingPackage.extraDeliveryTime
        packageStore.extraFastDeliveryPrice = pricingPackage.extraDeliveryFee
          ? $priceNumber(pricingPackage.extraDeliveryFee)
          : undefined
        packageStore.platforms = pricingPackage.platforms
        packageStore.licensingRights = pricingPackage.licensingRights

        if (
          pricingPackage.extraDelivery ||
          pricingPackage.platforms.length > 0
        ) {
          pricingStore.offerExtras = true
          pricingStore.updateFieldInAllPackages('offerExtras', true)
        }
      })
      packageNames.forEach((name) => {
        const packageStore = usePackageStore(name)
        if (!packageStore.id) {
          packageStore.isEnabled = false
        }
      })

      pricingStore.copyAdditionalAttributesFromResponse(gig)
    } catch (error) {
      $notify({
        severity: 'error',
        text: `Failed to get data of Collab with id ${id}`,
        sticky: true,
      })
      await navigateTo('/account/creator/collabs')
      console.error(error)
    }
  }
  const setValidationStatuses = (gig: GigResponse) => {
    const gigObjectData = gig.masterData.current
    const packages = usePackages(gigObjectData)
    validationStatuses.value = [
      !!gig.id,
      !!packages?.[0]?.contentType?.length,
      galleryStore.isValid,
      packages.some((el) => !!el.price),
    ]
  }

  return {
    gigVersion: skipHydrate(gigVersion),
    gigId: skipHydrate(gigId),
    isCreateNewGigPage: skipHydrate(isCreateNewGigPage),
    validationStatuses: skipHydrate(validationStatuses),
    isGigCreated,
    resetSteps,
    fetchGigData,
    setValidationStatuses,
  }
})
