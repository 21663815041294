import { skipHydrate } from 'pinia'
import { v4 as uuidv4 } from 'uuid'
import { useForm } from 'vee-validate'
import validationSchema from 'gig/validation-schema/create/package-pricing'
import type { PackageName } from 'gig/types/package-name'
import type { UpdateGigAction } from 'gig/types/create-gig'
import type { AttributeItem, NewVariant } from '@/types/variant'

export const usePackageStore = (packageName: PackageName) =>
  defineStore(`${packageName}PackageStore`, () => {
    const descriptionStore = useDescriptionStore()
    const detailsStore = useDetailsStore()
    const pricingStore = usePricingStore()
    const galleryStore = useGalleryStore()
    const { $priceObject } = useNuxtApp()

    const id = ref<number | undefined>()
    const sku = ref<string | undefined>()

    const isMasterVariant = ref(false)
    interface initStoreValues {
      description?: string
      revisions?: number
      deliveryTime?: string
      extraOptions: string[]
      price?: number
      extraFastDeliveryTime?: string
      extraFastDeliveryPrice?: number
      platforms: string[]
      licensingRights?: string
      isEnabled: boolean
      offerPackages: boolean
      offerExtras: boolean
    }

    const initialValues: initStoreValues = {
      description: '',
      revisions: undefined,
      deliveryTime: undefined,
      extraOptions: [],
      price: undefined,
      extraFastDeliveryTime: undefined,
      extraFastDeliveryPrice: undefined,
      platforms: [],
      licensingRights: undefined,
      isEnabled: true,
      offerPackages: true,
      offerExtras: false,
    }
    const form = useForm({ validationSchema, initialValues })
    const [description] = form.useFieldModel(['description'])
    const [revisions] = form.useFieldModel(['revisions'])
    const [deliveryTime] = form.useFieldModel(['deliveryTime'])
    const [extraOptions] = form.useFieldModel(['extraOptions'])
    const [price] = form.useFieldModel(['price'])
    const [extraFastDeliveryTime] = form.useFieldModel([
      'extraFastDeliveryTime',
    ])
    const [extraFastDeliveryPrice] = form.useFieldModel([
      'extraFastDeliveryPrice',
    ])
    const [platforms] = form.useFieldModel(['platforms'])
    const [licensingRights] = form.useFieldModel(['licensingRights'])
    const [isEnabled] = form.useFieldModel(['isEnabled'])
    const [offerPackages] = form.useFieldModel(['offerPackages'])
    const [offerExtras] = form.useFieldModel(['offerExtras'])

    const removePlatformsChip = (chipName: string) => {
      const newValue = platforms.value.filter(
        (item: string) => item !== chipName,
      )
      form.setFieldValue('platforms', newValue)
    }
    const includeExtraFastDelivery = computed(
      () => !!extraFastDeliveryTime.value && !!extraFastDeliveryPrice.value,
    )
    const getVariantData = (): NewVariant => {
      const { contentType, tags, contentIsAbout } = detailsStore
      const attributes: AttributeItem[] = [
        {
          name: 'content_type',
          value: toRaw(contentType),
        },
        {
          name: 'tags',
          value: toRaw(tags),
        },
        {
          name: 'content_is_about',
          value: toRaw(contentIsAbout),
        },
        {
          name: 'revisions',
          value: revisions.value as number,
        },
        {
          name: 'delivery_time',
          value: deliveryTime.value as string,
        },
        {
          name: 'extra_platforms',
          value: platforms.value as string[],
        },
        {
          name: 'content_creation',
          value: extraOptions.value.includes('content_creation'),
        },
        {
          name: 'post',
          value: extraOptions.value.includes('post'),
        },
        {
          name: 'engagement',
          value: extraOptions.value.includes('engagement'),
        },
        {
          name: 'extra_delivery',
          value: !!(offerPackages.value && includeExtraFastDelivery.value),
        },
        {
          name: 'requirements',
          value: descriptionStore.requirements,
        },
        {
          name: 'licensing_rights',
          value: licensingRights.value as string,
        },
        // Some attributes need to be the same across gig variants
        // In gig update, when adding a new variant, there are some additional attributes that need to be set
        ...pricingStore.additionalAttributesFromResponse,
      ]

      if (
        descriptionStore.usBased !== null &&
        descriptionStore.usBased !== undefined
      ) {
        attributes.push({
          name: 'us_based',
          value: descriptionStore.usBased,
        })
      }

      // --------------------------------------------------------- //

      if (offerPackages.value) {
        attributes.push(
          {
            name: 'variant_name',
            value: packageName,
          },
          {
            name: 'variant_description',
            value: description.value as string,
          },
        )

        if (offerExtras.value) {
          if (includeExtraFastDelivery.value) {
            attributes.push(
              {
                name: 'extra_delivery_time',
                value: extraFastDeliveryTime.value as string,
              },
              {
                name: 'extra_delivery_fee',
                value: $priceObject(extraFastDeliveryPrice.value as number),
              },
            )
          }
        }
      }

      return {
        prices: [
          {
            value: $priceObject(price.value as number),
          },
        ],
        attributes,
      }
    }
    const getUpdateActions = () => {
      /*
      Geneal explanation:
      * The order of actions matters. This is the execution order.
      * I add enabled packages actions first to avoid situation where there are no variants between executed actions.
      * Updating variants is complex and has a different structure than adding variants. As a workaround, I add a new variant and remove the old one.
      * Gig always needs to have a master variant. This means I can't remove it. I need to set a different variant as master first.
      * When doing actions on a newly added variant, I generate a unique sku and use it to reference the variant. Variant id doesn't exist at this point. It comes in api response after all actions are successfully executed.
      */
      const actions: UpdateGigAction[] = []
      const isNewMasterVariant = packageName === pricingStore.enabledPackages[0]
      const updateVariant = !!id.value && isEnabled.value
      const updateMasterVariant =
        updateVariant && (isMasterVariant.value || isNewMasterVariant)
      const addVariant = !id.value && isEnabled.value
      const addMasterVariant = addVariant && isNewMasterVariant
      const removeVariant = !!id.value && !isEnabled.value
      const removeMasterVariant = removeVariant && isMasterVariant.value

      if (updateMasterVariant) {
        sku.value = uuidv4()

        actions.push(
          {
            // Need to change name because gig can't have multiple variants with the same name
            action: 'setAttribute',
            staged: false,
            variantId: id.value as number,
            name: 'variant_name',
            value: `${packageName}-to-be-removed`,
          },
          {
            action: 'addVariant',
            staged: false,
            sku: sku.value,
            ...getVariantData(),
            assets: galleryStore.assets,
          },
        )

        if (isNewMasterVariant) {
          actions.push({
            action: 'changeMasterVariant',
            staged: false,
            sku: sku.value,
          })
        }

        actions.push({
          action: 'removeVariant',
          staged: false,
          id: id.value as number,
        })
      } else if (updateVariant) {
        sku.value = uuidv4()

        actions.push(
          {
            action: 'removeVariant',
            staged: false,
            id: id.value as number,
          },
          {
            action: 'addVariant',
            staged: false,
            sku: sku.value,
            ...getVariantData(),
          },
        )
      } else if (addMasterVariant) {
        sku.value = uuidv4()

        actions.push(
          {
            action: 'addVariant',
            staged: false,
            sku: sku.value,
            ...getVariantData(),
            assets: galleryStore.assets,
          },
          {
            action: 'changeMasterVariant',
            staged: false,
            sku: sku.value,
          },
        )
      } else if (addVariant) {
        actions.push({
          action: 'addVariant',
          staged: false,
          ...getVariantData(),
        })
      } else if (removeMasterVariant) {
        if (!isNewMasterVariant) {
          const newMasterVariantStore = usePackageStore(
            pricingStore.enabledPackages[0],
          )

          actions.push({
            action: 'changeMasterVariant',
            staged: false,
            sku: newMasterVariantStore.sku,
          })
        }

        actions.push({
          action: 'removeVariant',
          staged: false,
          id: id.value as number,
        })
      } else if (removeVariant) {
        actions.push({
          action: 'removeVariant',
          staged: false,
          id: id.value as number,
        })
      }

      return actions
    }

    return {
      id: skipHydrate(id),
      sku: skipHydrate(sku),
      isMasterVariant,
      description,
      revisions,
      deliveryTime,
      extraOptions,
      price,
      extraFastDeliveryTime,
      extraFastDeliveryPrice,
      platforms,
      licensingRights,
      isEnabled,
      offerPackages,
      offerExtras,
      form,
      removePlatformsChip,
      getVariantData,
      getUpdateActions,
    }
  })()
