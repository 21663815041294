import type { UnreadConversation } from 'talkjs/types/talk.types'
import type { ChatConversation } from 'account/types/chat'

export interface IChatState {
  conversations: ChatConversation[]
  unreads: UnreadConversation[]
  isFetching: boolean
  isFetched: boolean
}

export const useChatStore = defineStore('chatStore', () => {
  const conversations = ref<IChatState['conversations']>([])
  const unreads = ref<IChatState['unreads']>([])
  const isFetching = ref<IChatState['isFetching']>(false)
  const isFetched = ref<IChatState['isFetched']>(false)

  const getActiveConversations = computed(() =>
    conversations.value.filter((el) => el.lastMessage),
  )
  const getUnreadConversationsIds = computed(() =>
    unreads.value.map(({ lastMessage }) => lastMessage.conversation.id),
  )
  const hasUnreadConversation = computed(() => !!unreads.value.length)

  const fetchInbox = async (userId: string) => {
    if (isFetching.value) {
      return
    }

    let inbox: IChatState['conversations'] = []

    try {
      isFetching.value = true
      inbox = await useApi<{ data: ChatConversation[] }>(
        '/me/chat/conversations',
      ).then(({ data }) => {
        if (!data.value) {
          throw new Error('No conversation data found.')
        }

        return data.value.data
      })

      conversations.value = inbox
    } catch (err: any) {
      console.warn(
        `Issue while fetching chat inbox for ${userId}.`,
        err?.message,
      )
    } finally {
      isFetching.value = false
      isFetched.value = true
    }
  }

  const fetchUnreads = (payload: UnreadConversation[]) => {
    unreads.value = payload
  }

  return {
    conversations,
    unreads,
    isFetching,
    isFetched,
    getActiveConversations,
    getUnreadConversationsIds,
    hasUnreadConversation,
    fetchInbox,
    fetchUnreads,
  }
})
