<script setup lang="ts">
import {
  menuBrandItems,
  menuPublishedCreatorItems,
  menuNotPublishedCreatorItems,
} from '@/data/header/menu-customer'
import type { DropdownItem } from '@/types/menu-dropdown'
import type { PrimevueButton } from '@/types/primevue-button'

const customerStore = useCustomerStore()
const creatorStore = useCreatorStore()

defineProps<{
  email: string
  nameLetter: string
}>()

const authStore = useAuthStore()
const { hasUnreadConversation } = useChatInbox()
const { $intercom } = useNuxtApp()
// set commands if exist id must be the same as menu item id
const commands = [
  {
    id: 'logout',
    command: async () => {
      await authStore.logout()
      $intercom().logout()
    },
  },
]

const menuUserItems = computed(() => {
  if (customerStore.isCreator) {
    return creatorStore.isPublished
      ? menuPublishedCreatorItems
      : menuNotPublishedCreatorItems
  } else {
    return menuBrandItems
  }
})

const menuItems = computed<DropdownItem[]>(() => {
  const hasUnreadMessages = hasUnreadConversation.value

  return menuUserItems.value
    .reduce((acc: DropdownItem[], item: DropdownItem) => {
      if (item.command) {
        item.command = commands.find((command) => command.id === item.id)
          ?.command
      }

      if (item.id === 'messages') {
        item.unread = hasUnreadMessages
      }

      acc.push(item)

      return acc
    }, [])
    .sort((a, b) =>
      a.sortOrder && b.sortOrder ? a.sortOrder - b.sortOrder : 0,
    )
})

const menuDesktopItems = computed<DropdownItem[]>(() =>
  menuItems.value.filter((item) => item.isMdHidden),
)
const dropdownTriggerRef = ref<PrimevueButton | null>(null)
const buttonTriggerEl = ref<HTMLButtonElement | null | undefined>(null)

// pass template ref to child component
onMounted(() => {
  if (dropdownTriggerRef.value) {
    buttonTriggerEl.value = dropdownTriggerRef.value.$el
  }
})
</script>

<template>
  <ul class="ml-auto hidden md:flex md:justify-end md:pr-4 lg:pr-9">
    <li
      v-for="{ label, url, id, unread } in menuDesktopItems"
      :key="id"
      class="relative md:px-1 lg:px-2"
      :class="{
        'unread-item': unread,
      }"
    >
      <NuxtLink
        :to="url"
        class="border border-transparent p-2 text-sm font-medium text-gray-200 hover:text-blue-100 focus:text-blue-100"
        active-class="rounded-md !border-gray-500 text-blue-100"
        :aria-describedby="unread ? 'unread-msg' : undefined"
      >
        {{ label }}
        <span
          v-if="unread"
          id="unread-msg"
          class="sr-only"
        >
          You have unread message
        </span>
      </NuxtLink>
    </li>
  </ul>
  <BaseMenu
    :items="menuItems"
    dropdown-pos="right"
    dropdown-classes="focus-visible:!outline-white"
    trigger-label="Toggle Menu"
    :trigger-el="buttonTriggerEl"
    class="min-w-[78px]"
  >
    <template
      #default="{ isMenuOpen, toggleMenu, label, closeMenu, passFocus }"
    >
      <Button
        ref="dropdownTriggerRef"
        :class="{
          'absolute right-3 top-[19px] z-50 w-auto min-w-[140px] border-blue-200 !bg-white  shadow-around xl:right-0':
            isMenuOpen,
        }"
        class="dropdown-trigger p-button-outline group h-11 rounded-full border border-gray-400 bg-transparent p-1.5 text-gray-100 focus-visible:bg-blue-600 disabled:!bg-white"
        aria-haspopup="menu"
        :aria-expanded="isMenuOpen"
        :label="label"
        :disabled="!dropdownTriggerRef"
        @click="toggleMenu"
        @keydown.escape="closeMenu"
        @keydown.tab="passFocus"
      >
        <BaseAvatar
          :src="customerStore.profilePicture"
          :alt="`${customerStore.customer?.middleName} avatar`"
          size="small"
          class="mr-2"
          :user-name="customerStore.customer?.firstName"
        />
        <i
          v-if="!isMenuOpen"
          class="pi pi-bars mr-2 text-gray-100 transition-opacity group-focus:text-blue-200"
          :class="{ 'opacity-30': !dropdownTriggerRef }"
        />
        <template v-else>
          <span class="mx-2 flex-grow group-hover:text-blue-200">{{
            email
          }}</span>
          <i class="pi pi-times mr-2 text-blue-200" />
        </template>
      </Button>
    </template>
  </BaseMenu>
</template>

<style scoped>
.dropdown-trigger:enabled:hover {
  @apply border-gray-400 bg-white shadow-hover;
}
</style>
