import type { CustomerData } from 'account/types/customer'

export interface IntercomUser {
  email: string
  createdAt: number
  name: string
  userId: string
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      intercom: () => ({
        login: (customer: CustomerData) => {
          const user: IntercomUser = {
            name: customer.middleName,
            email: customer.email,
            createdAt: new Date(customer.createdAt).getTime(),
            userId: customer.id,
          }

          if (Object.values(user).every(Boolean)) {
            if (window?.Intercom) {
              window.Intercom('update', user)
            } else {
              window.intercomSettings = user
            }
          }
        },
        logout: () => {
          if (window?.Intercom) {
            window.Intercom('shutdown')
          }
        },
      }),
    },
  }
})
