<script setup lang="ts">
import { storeToRefs } from 'pinia'
import IconLogo from '@/assets/images/logo.svg'

const authStore = useAuthStore()
const customerStore = useCustomerStore()
const { isLoggedIn } = storeToRefs(authStore)
const { isCreator } = storeToRefs(customerStore)
const notificationsStore = useNotificationsStore()
const header = ref<HTMLElement | null>(null)

const creatorStore = useCreatorStore()
const url = computed(() =>
  creatorStore.isPublished ? '/account/dashboard' : '/',
)
const ariaLabel = computed(() =>
  creatorStore.isPublished ? 'Go to Dashboard' : 'Go to Homepage',
)

useIntersectionObserver(header, ([{ isIntersecting }]) => {
  notificationsStore.headerIsVisible = isIntersecting
})
</script>

<template>
  <header
    ref="header"
    class="z-50 shadow-light"
  >
    <div
      :class="[
        'container relative flex h-20 w-full items-center',
        { 'justify-between': isCreator },
      ]"
    >
      <NuxtLink
        :to="url"
        :aria-label="ariaLabel"
      >
        <IconLogo
          class="w-[102px]"
          aria-hidden="true"
        />
      </NuxtLink>
      <HeaderSearchWrapper
        v-if="!isCreator"
        class="mr-7 flex max-w-[590px] grow justify-end md:mr-2 md:block lg:mx-9"
      >
        <template #default="{ closeSearch }">
          <AppSearch :close-search="closeSearch" />
        </template>
      </HeaderSearchWrapper>
      <nav
        aria-label="Main navigation"
        class="ml-auto flex items-center"
      >
        <HeaderMenuUser
          v-if="isLoggedIn"
          :email="customerStore.email"
          :name-letter="customerStore.nameLetter"
        />
        <HeaderMenuGuest v-else />
      </nav>
    </div>
  </header>
</template>
