import { skipHydrate, storeToRefs } from 'pinia'
import type { ProductGridItem } from 'gig/types/product-grid-item'
import type { CreatorWithStats } from 'profile/types/profile'

export interface Favourites<T> {
  results: T[]
  total: number
}

export const useFavoritesStore = defineStore('favoritesStore', () => {
  const gigIds = ref<string[]>([])
  const creatorIds = ref<string[]>([])
  const runtimeConfig = useRuntimeConfig()

  const { $price, $notify } = useNuxtApp()

  const getFavoriteGigIds = async () => {
    try {
      const { data, error } = await useApi<string[]>('me/favourites/gig')

      if (error.value) {
        throw new Error(error.value.message)
      }

      if (data.value) {
        gigIds.value = data.value
      }
    } catch (error) {
      console.error(
        (error as Error).message || 'Failed to get favorite Collabs',
      )
    }
  }

  const getFavoriteCreatorIds = async () => {
    try {
      const { data, error } = await useApi<string[]>('me/favourites/profile')

      if (error.value) {
        throw new Error(error.value.message)
      }

      if (data.value) {
        creatorIds.value = data.value
      }
    } catch (error) {
      console.error(
        (error as Error).message || 'Failed to get favorite creators',
      )
    }
  }

  const fetchGigs = async (
    limit = 10,
  ): Promise<Favourites<ProductGridItem>> => {
    if (gigIds.value.length === 0) {
      return { results: [], total: 0 }
    }

    const params = {
      expand: 'masterVariant.attributes[*].value',
      limit,
      where: `id in ("${gigIds.value.join('", "')}") and productType(id=:gig)`,
    }

    const { results, total } = await useGigs(params)

    return {
      results: results.map((item) => ({
        id: item.id,
        name: item.name,
        image: item.assets[0]?.image || item.assets[0]?.thumbnail,
        avatar: item.creator.owner?.id
          ? `${runtimeConfig.public.uploadUrl}profile/${item.creator.owner?.id}`
          : undefined,
        creatorName: item.creator.name,
        rating: item?.reviewRatingStatistics?.averageRating,
        price: $price(item.packages[0].price),
        link: `/collab/${item.slug}`,
        ownerId: item.creator.owner?.id,
        socials: item.creator.socials,
      })) as ProductGridItem[],
      total,
    }
  }

  const fetchCreators = async (
    limit = 10,
  ): Promise<Favourites<CreatorWithStats>> => {
    if (creatorIds.value.length === 0) {
      return { results: [], total: 0 }
    }

    const params = {
      expand: 'masterVariant.attributes[*].value',
      limit,
      where: `id in ("${creatorIds.value.join(
        '", "',
      )}") and productType(id=:creator)`,
    }

    const products = await useFetchProducts(params)

    try {
      if (!products.results.length) {
        throw new Error('No creators data')
      }
    } catch (error: unknown) {
      throw createError({
        statusCode: 404,
        statusMessage: (error as Error).message,
        fatal: true,
      })
    }

    return {
      results: products.results.map((product) => {
        const creator = useCreator(product)
        return {
          ...creator,
          stats: getStats(creator),
        }
      }),
      total: products.total,
    }
  }

  const updateFavorite = async (productId: string, type: 'gig' | 'profile') => {
    const authStore = useAuthStore()
    const route = useRoute()
    const { isDialogVisible, isLoggedIn } = storeToRefs(authStore)

    if (!isLoggedIn.value) {
      isDialogVisible.value = true
      navigateTo({
        query: {
          redirect: `${route.fullPath}?favId=${productId}&favType=${type}`,
        },
      })
      return
    }

    try {
      const { data, error } = await useApi<string[]>(`me/favourites/${type}`, {
        method: 'post',
        isFormData: true,
        body: new URLSearchParams({
          productId,
        }),
      })

      if (error.value) {
        throw new Error(error.value.message)
      }

      if (data.value) {
        if (type === 'gig') {
          gigIds.value = data.value
        } else if (type === 'profile') {
          creatorIds.value = data.value
        }

        $notify('Favorites list updated.')
      }
    } catch (error) {
      $notify({
        text: (error as Error).message || 'Failed to update favorites list.',
        severity: 'error',
      })
    }
  }

  return {
    gigIds: skipHydrate(gigIds),
    creatorIds: skipHydrate(creatorIds),
    getFavoriteGigIds,
    getFavoriteCreatorIds,
    fetchGigs,
    fetchCreators,
    updateFavorite,
  }
})
