<script setup lang="ts">
import type { ErrorResponse } from '@/types/api'

defineProps<{
  error: ErrorResponse
}>()

const handleError = () => clearError({ redirect: '/' })
</script>

<template>
  <div class="flex h-screen flex-col">
    <AppHeader />

    <div
      class="flex flex-1 items-center justify-center bg-gray-600 px-4 py-16 text-center"
    >
      <div class="max-w-3xl">
        <h1 class="mb-6 text-6xl">{{ error.statusCode }}</h1>

        <p class="mb-6 text-xl">{{ error.message }}</p>

        <Button @click="handleError">Go back home</Button>
      </div>
    </div>

    <AppFooter />
  </div>
</template>
