import type { ConversationType } from 'account/types/chat'

export default async (
  id: string,
  context: ConversationType,
): Promise<string | undefined> => {
  const { $notify } = useNuxtApp()
  try {
    const { data: conversationData, error } = await useApi<{
      conversation: string
    }>(`chat/${context === 'order' ? 'order/' : ''}${id}`)

    if (error.value || !conversationData.value) {
      throw new Error('Can not find conversation id.')
    }

    return conversationData.value.conversation
  } catch (error) {
    $notify({
      text: (error as Error).message,
      severity: 'error',
    })
  }
}
