import { object, number, string } from 'yup'

export default object({
  description: string().when(['isEnabled', 'offerPackages'], {
    is: true,
    then: (schema) => schema.required().min(5).max(100),
  }),
  revisions: number().when('isEnabled', {
    is: true,
    then: (schema) => schema.required(),
  }),
  deliveryTime: string().when('isEnabled', {
    is: true,
    then: (schema) => schema.required(),
  }),
  price: number().when('isEnabled', {
    is: true,
    then: (schema) =>
      schema.typeError('This is a required field').required().max(10000000),
  }),
  licensingRights: string().when('isEnabled', {
    is: true,
    then: (schema) => schema.required(),
  }),
})
