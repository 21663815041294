import type {
  LogoList,
  Social,
  SocialInterests,
  Creator,
} from 'profile/types/profile'
import type { Product } from '@/types/products'
import type {
  ItemCreatorDetail,
  ItemLocationCountry,
  ItemLocationState,
  ItemLocationCity,
  ItemDeliveryOnTime,
  ItemOwner,
  ItemSocial,
  ItemSocialInterests,
  ItemProjectsInQueue,
  ItemTotalProjects,
  ItemResponseTime,
} from '@/types/variant'

export default (product: Product) => {
  const attrs = product.masterVariant.attributes
  const getVal = <T>(name: string) =>
    attrs.find((attribute) => attribute.name === name)?.value as T

  const formatPercentage = (rate?: number) => {
    if (!rate) {
      return undefined
    }
    const percentage = rate * 100
    const decimalPlaces = percentage < 10 ? 2 : percentage < 100 ? 1 : 0
    return percentage.toFixed(decimalPlaces) + '%'
  }

  const socials: Social[] =
    getVal<ItemSocial['value']>('social')
      ?.map((social) => {
        const getSocialAttr = (name: string) =>
          social.find((el) => el.name === name)?.value
        const id = getSocialAttr('social_platform') as string | undefined
        const name = getSocialAttr('social_display_name') as string | undefined
        const followersCount = getSocialAttr('social_followers_count') as
          | number
          | undefined
        const url = getSocialAttr('url') as string | undefined
        const engagementRate = formatPercentage(
          getSocialAttr('social_engagement_rate') as number | undefined,
        )
        return { id, name, followersCount, url, engagementRate }
      })
      // sort by follower count (descendent)
      .sort((a, b) => {
        if (a.followersCount && b.followersCount) {
          return b.followersCount - a.followersCount
        }
        return 0
      }) || []

  const socialInterests: SocialInterests[] = getVal<
    ItemSocialInterests['value']
  >('social_interests')?.map((interest) => {
    return {
      id: interest[0].value,
      value: interest[1].value,
    }
  })

  const linkedPlatforms =
    socials?.map((item) => String(item.id).toLowerCase()) || []

  const socialsWithInterests: LogoList['list'] =
    socials?.reduce<LogoList['list']>((acc, item) => {
      const platformId = String(item.id).toLowerCase()
      const platformInterests = socialInterests?.filter(
        (item) => item.id === platformId,
      )
      const url = item.url || ''
      acc.push({
        id: platformId,
        interests: platformInterests?.[0]?.value || [],
        url,
      })
      return acc
    }, []) || []

  const owner = getVal<ItemOwner['value']>('owner')

  const country = getCountryName(
    getVal<ItemLocationCountry['value']>('location_country') || '',
  )

  const creator: Creator = {
    id: product.id,
    createdAt: new Date(product.createdAt).getFullYear(),
    name: product.name['en-US'],
    slug: product.slug['en-US'],
    description: product.description['en-US'],
    country,
    state: getVal<ItemLocationState['value']>('location_state'),
    city: getVal<ItemLocationCity['value']>('location_city'),
    reviewRatingStatistics: product?.reviewRatingStatistics,
    avatar: product.masterVariant.images[0]?.url,
    creatorBadge:
      getVal<ItemCreatorDetail['value']>('creator_detail')?.label['en-US'],
    deliveryOnTime: getVal<ItemDeliveryOnTime['value']>('delivery_on_time'),
    projectsInQueue: getVal<ItemProjectsInQueue['value']>('projects_in_queue'),
    totalProjects: getVal<ItemTotalProjects['value']>('total_projects'),
    responseTime: getVal<ItemResponseTime['value']>('response_time'),
    assets: product.masterVariant.assets,
    socials,
    socialInterests,
    linkedPlatforms,
    owner,
    socialsWithInterests,
  }

  return creator
}
