/**
 * Converts text to slug (url).
 * New slug has lowercase, spaces and dots are replaced with dashes.
 * Special characters other than `-` or `_` are removed.
 * @example
 * // returns 'my-title-1'
 * convertToSlug('My title.1##')
 */
export const convertToSlug = (text: string) =>
  text
    .toLowerCase()
    .replaceAll(/\s|\./g, '-')
    .replaceAll(/[^a-zA-Z0-9_\\-]/g, '')

/**
 * Generate new slug (url) with 5 random digits at the end.
 * @example
 * // returns 'my-url-62943'
 * suggestNewSlug('my-url')
 */
export const suggestNewSlug = (slug: string) => {
  const newSlug = `${slug}-${Math.floor(Math.random() * 90000) + 10000}`
  return {
    newSlug,
    newErrorMessage: `The selected url is already taken, try another or use the suggested one: ${newSlug}`,
  }
}
