import type { Ref } from 'vue'
import type { DropdownPosition } from '@/types/menu-dropdown'
type HTMLelRef = Ref<HTMLElement | HTMLButtonElement | null | undefined>

export function useDropdownPosition(
  wrapper: HTMLelRef,
  trigger: HTMLelRef,
  position: DropdownPosition,
) {
  const { width } = useWindowSize()
  const { y } = useWindowScroll()
  const triggerPos = reactive(useElementBounding(trigger))
  const dropdownPos = reactive(useElementBounding(wrapper))
  const windowWidth = refDebounced(width, 200)
  const rightPos = ref('0')
  const leftPos = ref('0')
  const topPos = ref('auto')
  const maxWidth = ref('0')

  const setPosition = (position: DropdownPosition) => {
    maxWidth.value = 'none'

    const isDropdownOutOfWindow =
      windowWidth.value <=
      dropdownPos.width +
        (windowWidth.value - (triggerPos.x + triggerPos.width))
    const isDropdownWiderThanContainer =
      windowWidth.value <= dropdownPos.width - 24
    topPos.value = `${triggerPos.height + triggerPos.top + y.value}px`

    if (position === 'right' || isDropdownOutOfWindow) {
      if (isDropdownOutOfWindow) {
        rightPos.value = 'auto'
      } else {
        rightPos.value = `${windowWidth.value - triggerPos.right}px`
      }

      leftPos.value = 'auto'
    } else {
      leftPos.value = `${triggerPos.left}px`
      rightPos.value = 'auto'
    }

    if (isDropdownWiderThanContainer) {
      maxWidth.value = `${windowWidth.value - 24}px`
    } else {
      maxWidth.value = 'none'
    }
  }
  watch(windowWidth, () => {
    setPosition(position)
  })

  onMounted(() => {
    setPosition(position)
  })

  return {
    rightPos,
    leftPos,
    topPos,
    maxWidth,
  }
}
