<script setup lang="ts">
import type { HeaderMenuGuestItemProps } from './MenuGuestItem.vue'

export interface MenuGuestDesktopProps {
  menuItems: HeaderMenuGuestItemProps[]
  isMobile: boolean
}

const props = defineProps<MenuGuestDesktopProps>()
const isMobileRef = toRef(props, 'isMobile')
const showAuth = ref(true)

watch(isMobileRef, () => {
  showAuth.value = !isMobileRef.value
})

onMounted(() => {
  showAuth.value = !props.isMobile
})
</script>

<template>
  <div>
    <ul
      class="relative left-auto right-auto top-0 z-50 ml-auto flex w-auto gap-2 border-0"
    >
      <li>
        <LazyAuthDialog v-if="showAuth" />
      </li>
      <HeaderMenuGuestItem
        v-for="{ url, label, hasButtonLookMd } in menuItems"
        :key="`${url}-desktop`"
        :url="url"
        :label="label"
        :has-button-look-md="hasButtonLookMd"
      />
    </ul>
  </div>
</template>
