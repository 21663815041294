import type { Gig } from 'gig/types/gig'
import type { Params } from '@/types/params'

export default async (params: Params) => {
  const products = await useFetchProducts(params)

  const gigs = {
    ...products,
    results: products.results.map((item): Gig => {
      const creator: any = item.masterVariant.attributes.find(
        (item) => item.name === 'creator',
      )?.value

      return {
        id: item.id,
        createdAt: item.createdAt,
        name: item.name['en-US'],
        slug: item.slug['en-US'],
        description: item.description?.['en-US'] || '',
        reviewRatingStatistics: item?.reviewRatingStatistics,
        assets: useAssets(item.masterVariant),
        contentType: item.masterVariant.attributes.find(
          (item: any) => item.name === 'content_type',
        )?.value,
        contentIsAbout: item.masterVariant.attributes.find(
          (item: any) => item.name === 'content_is_about',
        )?.value,
        creator: useCreator(creator.obj.masterData.current),
        packages: usePackages(item),
      }
    }),
  }
  return gigs
}
