export default defineNuxtPlugin(() => {
  onNuxtReady(() => {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        const authStore = useAuthStore()
        const accessTokenCookie = useCookie('access_token')
        if (
          (authStore.token || accessTokenCookie.value) &&
          authStore.token !== accessTokenCookie.value
        ) {
          location.reload()
        }
      }
    })
  })
})
