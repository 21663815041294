import type { FetchOptions } from 'ofetch'

interface ExtFetchOptions extends FetchOptions {
  isFormData?: boolean
  isUploadForm?: boolean
  method?: 'get' | 'post' | 'put' | 'delete'
}
export const useApi = <Data = unknown>(
  endpoint: string,
  options?: ExtFetchOptions,
) => {
  const runtimeConfig = useRuntimeConfig()
  const defaultBaseURL = options?.isUploadForm
    ? runtimeConfig.public.uploadUrl
    : runtimeConfig.public.apiUrl

  let contentTypeHeader
  if (options?.isUploadForm) {
    // Allow fetch to detect and set boundary and content type (multipart/form-data) automatically. Otherwise upload request fails.
    contentTypeHeader = undefined
  } else if (options?.isFormData) {
    contentTypeHeader = 'application/x-www-form-urlencoded'
  } else {
    contentTypeHeader = 'application/json'
  }
  const headers: { 'Content-Type'?: string; Authorization?: string } = {}
  if (contentTypeHeader) {
    headers['Content-Type'] = contentTypeHeader
  }
  const authStore = useAuthStore()
  const authHeader = authStore.isLoggedIn ? `Bearer ${authStore.token}` : null
  if (authHeader) {
    headers.Authorization = authHeader
  }

  // TODO: Remove extra typing after https://github.com/nuxt/nuxt/pull/20797
  return useFetch<Data, Error, string, 'post' | 'get' | 'put' | 'delete'>(
    endpoint,
    {
      baseURL: options?.baseURL || defaultBaseURL,
      method: options?.method || 'get',
      body: options?.body,
      headers,
      params: options?.params,
      onResponse({ response }) {
        return response._data
      },
      onResponseError({ response }) {
        if (response.status === 401 && process.client) {
          const { $notify } = useNuxtApp()
          authStore.resetAuthData()
          $notify({
            text: 'Your session has expired, please login again',
            severity: 'error',
          })
          navigateTo('/')
        }
        throw response._data
      },
    },
  )
}
