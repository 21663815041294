import type { Step } from '@/types/steps'

const createGigSteps = (slug: string): Step[] => {
  return [
    {
      label: 'Description',
      to: `/create-collab/${slug}/description`,
    },
    {
      label: 'Details',
      to: `/create-collab/${slug}/details`,
    },
    {
      label: 'Gallery',
      to: `/create-collab/${slug}/gallery`,
    },
    {
      label: 'Pricing',
      to: `/create-collab/${slug}/pricing`,
    },
  ]
}
export default createGigSteps
