<script setup lang="ts">
import searchSuggestions from '@/data/search/autocomplete-suggestions'

const searchValue = ref('')
const suggestions = ref<string[]>([])
const minInputLength = 3

const tooltipText = computed(() => {
  return searchValue.value.length < minInputLength
    ? `Enter at least ${minInputLength} characters to search in store`
    : 'Search in store'
})

interface AppSearchProps {
  closeSearch?: () => void
  large?: boolean
}
const props = defineProps<AppSearchProps>()

const loaderVisible = ref(false)
const appSearch = ref<
  HTMLDivElement & { $el: HTMLDivElement; overlayVisible: boolean }
>()

const getSuggestions = () => {
  loaderVisible.value = true

  suggestions.value = useSearchSuggestions(searchValue.value, searchSuggestions)

  loaderVisible.value = false
}

const submitSearch = () => {
  if (searchValue.value) {
    navigateTo(`/search/${searchValue.value}`)
    searchValue.value = ''
    props.closeSearch?.()
  }
}

const searchOnEnterKey = () => {
  if (
    !appSearch.value?.overlayVisible &&
    searchValue.value.length >= minInputLength
  ) {
    submitSearch()
  }
}
</script>

<template>
  <div
    class="p-input-icon-right p-fluid"
    :title="tooltipText"
  >
    <label
      for="search-input"
      class="sr-only"
    >
      Search
    </label>
    <AutoComplete
      id="appsearch"
      ref="appSearch"
      v-model="searchValue"
      input-id="search-input"
      :input-class="large ? '!h-[55px]' : ''"
      :class="[
        { 'loader-large': large },
        'p-autocomplete-rounded md:block md:w-full',
      ]"
      placeholder="i.e. beauty influencer"
      :dropdown="false"
      :multiple="false"
      :suggestions="suggestions"
      :delay="300"
      @complete="getSuggestions"
      @item-select="submitSearch"
      @keyup.enter="searchOnEnterKey"
    />
    <Button
      icon="pi pi-search"
      :class="[
        large ? '!h-[47px] !w-[47px]' : '',
        'p-button-rounded p-button-info pointer-events-auto',
        loaderVisible ? 'icon-hidden' : '',
      ]"
      :aria-label="tooltipText"
      @click="submitSearch"
    />
  </div>
</template>

<style scoped>
:deep(.p-autocomplete-loader) {
  @apply right-0 z-10 mr-[1px];
}

:deep(.loader-large .p-autocomplete-loader) {
  @apply right-1 text-xl;
}

:deep(.icon-hidden .p-button-icon) {
  @apply invisible;
}

:deep(.pi-spinner) {
  @apply text-lg text-white;
}
</style>
