import ageRanges from 'account/data/age-ranges'

function calculateAge(birthDate: Date) {
  const birthYear = birthDate.getFullYear()
  const birthMonth = birthDate.getMonth()
  const birthDay = birthDate.getDate()

  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()
  const currentDay = currentDate.getDate()

  let age = currentYear - birthYear

  if (
    currentMonth < birthMonth ||
    (currentMonth === birthMonth && currentDay < birthDay)
  ) {
    age--
  }

  return age
}

export function getAgeRange(birthDate: Date) {
  const age = calculateAge(birthDate)
  for (const [range, [min, max]] of Object.entries(ageRanges)) {
    if (age >= min && age <= max) {
      return range
    }
  }
}
