<script setup lang="ts">
import type { DropdownItem, DropdownPosition } from '@/types/menu-dropdown'

interface MenuDropdownEl {
  items: DropdownItem[]
  dropdownPos: DropdownPosition
  menuTrigger?: HTMLButtonElement | null
  dropdownClasses?: string
  type?: string
}

const emit = defineEmits<{ (e: 'close'): void; (e: 'click-outside'): void }>()
const props = defineProps<MenuDropdownEl>()
const wrapper = ref<HTMLElement | null | undefined>(null)
const menuItems = reactive<DropdownItem[]>(props.items)
const triggerRef = ref(props.menuTrigger)
const { rightPos, leftPos, topPos, maxWidth } = useDropdownPosition(
  wrapper,
  triggerRef,
  props.dropdownPos,
)
const focusOut = (ev: any) => {
  if (wrapper.value?.contains(ev.relatedTarget)) {
    return
  }

  // TO DO in a11y task - focus run outside of the document, so it doesn't work, focus trap needed
  // temporarily added focusable div after items list to fix focus issue
  triggerRef.value?.focus()
}

defineExpose({ wrapper })

onClickOutside(
  wrapper,
  () => {
    emit('click-outside')
  },
  {
    ignore: [props.menuTrigger],
  },
)

onUpdated(() => {
  wrapper.value?.focus()
})
</script>

<template>
  <Teleport
    to="body"
    :disabled="!!type"
  >
    <ul
      ref="wrapper"
      :class="[
        'absolute z-[101] mt-2 block min-w-[200px] overflow-hidden rounded-md border border-gray-400 bg-white shadow-around shadow-gray-400',
        { 'w-full': type },
      ]"
      tabindex="0"
      :style="
        !type
          ? {
              right: rightPos,
              left: leftPos,
              top: topPos,
              maxWidth: maxWidth,
            }
          : {}
      "
      @keydown.escape="$emit('close')"
      @focusout="focusOut"
      @click="$emit('close')"
    >
      <template v-for="(item, index) in menuItems">
        <BaseMenuItem
          v-if="!item.isHidden"
          :key="index"
          :item="item"
          @command="item.command"
        />
      </template>
    </ul>

    <div
      tabindex="0"
      class="w-1"
    />
  </Teleport>
</template>
