import type { AssetData } from '@/types/asset'
import type { MasterVariant } from '@/types/variant'

export default (masterVariant: MasterVariant): AssetData[] =>
  masterVariant.assets?.map((asset) => ({
    image: asset.sources.find((source) => source.key === 'image')?.uri,
    thumbnail: asset.sources.find((source) => source.key === 'thumbnail')?.uri,
    dimensions: {
      w: asset.sources.find(
        // Asset always has either image or thumbnail
        (source) => source.key === 'image' || source.key === 'thumbnail',
      )?.dimensions?.w as number,
      h: asset.sources.find(
        (source) => source.key === 'image' || source.key === 'thumbnail',
      )?.dimensions?.h as number,
    },
    video: asset.sources.find((source) => source.key === 'video')?.uri,
    name: asset.name,
  })) || []
