/*
Pinia plugin that adds a $reset() function for Composition API
Stores built using the setup syntax don't implement $reset() like this https://pinia.vuejs.org/core-concepts/state.html#resetting-the-state
*/
import cloneDeep from 'lodash.clonedeep'
import type { PiniaPluginContext } from 'pinia'
import type { NuxtAppWithPinia } from '@/types/nuxtApp'

function resetPiniaStore({ store }: PiniaPluginContext) {
  const initialState = cloneDeep(store.$state)
  store.$reset = () => {
    store.$patch(cloneDeep(initialState))
    store.form?.resetForm()
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const { $pinia } = nuxtApp as NuxtAppWithPinia
  $pinia.use(resetPiniaStore)
})
