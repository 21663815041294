import { skipHydrate } from 'pinia'
import type { ComputedRef } from 'vue'
import { useForm } from 'vee-validate'
import { publicAttributes } from 'gig/data/attributes'
import { packageNames } from 'gig/data/package-names'
import type { GigResponse, UpdateGigData } from 'gig/types/create-gig'
import type { PackageName } from 'gig/types/package-name'
import type { AttributeItem, NewVariant } from '@/types/variant'

export const usePricingStore = defineStore('createGigPricingStore', () => {
  const createGigStore = useGigCreateStore()
  const packageStores = Object.fromEntries(
    packageNames.map((packageName) => [
      packageName,
      usePackageStore(packageName),
    ]),
  )
  const { $notify } = useNuxtApp()

  const isProcessing = ref(false)
  const additionalAttributesFromResponse = ref<AttributeItem[]>([])
  const form = useForm({
    initialValues: {
      offerPackages: true,
      offerExtras: false,
    },
  })
  const [offerPackages, offerExtras] = form.useFieldModel([
    'offerPackages',
    'offerExtras',
  ])
  const { isSubmitted: isFormSubmitted } = useValidation(form.submitCount)
  const arePackageFieldsValid = computed(() => {
    const valid = []

    if (offerPackages.value) {
      enabledPackages.value.forEach((packageName) => {
        valid.push(packageStores[packageName].form.meta.valid)
      })
    } else {
      valid.push(packageStores.Basic.form.meta.valid)
    }

    return valid.every((el) => el)
  })
  const enabledPackages: ComputedRef<PackageName[]> = computed(() => {
    const list: PackageName[] = []
    for (const [name, store] of Object.entries(packageStores)) {
      if (store.isEnabled) {
        list.push(name as PackageName)
      }
    }

    return list
  })
  const disabledPackages: ComputedRef<PackageName[]> = computed(() => {
    const list: PackageName[] = []
    for (const [name, store] of Object.entries(packageStores)) {
      if (!store.isEnabled) {
        list.push(name as PackageName)
      }
    }

    return list
  })
  const isAtLeastOnePackageEnabled = computed(
    () => enabledPackages.value.length > 0,
  )
  const masterVariantId = computed(
    () =>
      Object.values(packageStores).find((store) => store.isMasterVariant)?.id,
  )

  const updateFieldInAllPackages = (
    fieldName: 'offerExtras' | 'offerPackages',
    fieldValue: boolean,
  ) => {
    Object.values(packageStores).forEach((store) => {
      store[fieldName] = fieldValue
    })
  }
  const getPackagesData = (): NewVariant[] => {
    const data: NewVariant[] = []
    const stores = offerPackages.value
      ? packageStores
      : { Basic: packageStores.Basic }

    Object.values(stores).forEach((store) => {
      if (store.isEnabled) {
        data.push(store.getVariantData())
      }
    })

    return data
  }
  const copyAdditionalAttributesFromResponse = (gig: GigResponse) => {
    const attributes = gig.masterData.current.masterVariant.attributes
    // The value of these attributes must be the same across all variants.
    // take all values from gig table Constraint = 'Same for all'
    // https://kb.snowdog.dev/link/1671#bkmrk-gig
    // The ones that are visible in ui (content_type, tags, content_is_about)
    // are set through setAttributeInAllVariants
    additionalAttributesFromResponse.value = []

    publicAttributes.forEach((name) => {
      const attribute = attributes.find((item) => item.name === name)

      attribute && additionalAttributesFromResponse.value.push(attribute)
    })
  }
  const setVariantsData = (gig: GigResponse) => {
    const gigObjectData = gig.masterData.current
    const variants = gigObjectData.variants
    variants.unshift(gigObjectData.masterVariant)
    variants.forEach((item, index) => {
      const name =
        item.attributes.find((item) => item.name === 'variant_name')?.value ||
        packageNames[0]
      packageStores[name as PackageName].id = item.id
      packageStores[name as PackageName].isMasterVariant = index === 0
    })
    disabledPackages.value.forEach((name) => {
      packageStores[name].id = undefined
      packageStores[name].sku = undefined
      packageStores[name].isMasterVariant = false
    })
  }

  const updateGig = async (withPublishing = false) => {
    if (!createGigStore.gigVersion) {
      return false
    }
    isProcessing.value = true
    try {
      const body: UpdateGigData = {
        version: createGigStore.gigVersion,
        actions: [],
      }
      // Need to apply enabled packages' actions first to avoid situation where there are no variants between executed actions
      const packages = [...enabledPackages.value, ...disabledPackages.value]
      packages.forEach((packageName) => {
        body.actions = [
          ...body.actions,
          ...packageStores[packageName].getUpdateActions(),
        ]
      })

      if (withPublishing) {
        body.actions.push({
          action: 'publish',
          scope: 'All',
        })
      }

      const { data, error } = await useApi<GigResponse>(
        `me/product/${createGigStore.gigId}`,
        {
          method: 'post',
          body,
        },
      )
      const gig = data.value
      if (error.value || !gig) {
        throw new Error(
          error.value?.message || 'Failed to save Collab changes.',
        )
      }
      $notify('Saved Collab changes')
      createGigStore.gigVersion = gig.version
      createGigStore.setValidationStatuses(gig)
      setVariantsData(gig)
      copyAdditionalAttributesFromResponse(gig)
      return true
    } catch (error) {
      $notify({
        text: (error as Error).message,
        severity: 'error',
      })
      return false
    } finally {
      isProcessing.value = false
    }
  }
  const resetPackageStores = () => {
    Object.values(packageStores).forEach((store) => {
      store.$reset()
    })
  }

  return {
    isProcessing: skipHydrate(isProcessing),
    additionalAttributesFromResponse: skipHydrate(
      additionalAttributesFromResponse,
    ),
    packageStores,
    offerPackages,
    offerExtras,
    form,
    isFormSubmitted,
    arePackageFieldsValid,
    enabledPackages,
    isAtLeastOnePackageEnabled,
    masterVariantId,
    updateFieldInAllPackages,
    setVariantsData,
    getPackagesData,
    copyAdditionalAttributesFromResponse,
    updateGig,
    resetPackageStores,
  }
})
