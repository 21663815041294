import revive_payload_client_jBVHgeKnnL from "/app/node_modules/.pnpm/nuxt@3.8.2_@types+node@20.10.3_eslint@8.55.0_typescript@5.2.2_vite@4.5.1_vue-tsc@1.8.15/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_19Zp1zMIen from "/app/node_modules/.pnpm/nuxt@3.8.2_@types+node@20.10.3_eslint@8.55.0_typescript@5.2.2_vite@4.5.1_vue-tsc@1.8.15/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_lliErzVZtL from "/app/node_modules/.pnpm/nuxt@3.8.2_@types+node@20.10.3_eslint@8.55.0_typescript@5.2.2_vite@4.5.1_vue-tsc@1.8.15/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_fuSQrSjHF9 from "/app/node_modules/.pnpm/nuxt@3.8.2_@types+node@20.10.3_eslint@8.55.0_typescript@5.2.2_vite@4.5.1_vue-tsc@1.8.15/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_onvedoSV5x from "/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@5.2.2_vue@3.3.10/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_EDWcanDoTw from "/app/node_modules/.pnpm/nuxt@3.8.2_@types+node@20.10.3_eslint@8.55.0_typescript@5.2.2_vite@4.5.1_vue-tsc@1.8.15/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_wqazCpyqmY from "/app/node_modules/.pnpm/nuxt@3.8.2_@types+node@20.10.3_eslint@8.55.0_typescript@5.2.2_vite@4.5.1_vue-tsc@1.8.15/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_LmOXZwF0bS from "/app/node_modules/.pnpm/nuxt@3.8.2_@types+node@20.10.3_eslint@8.55.0_typescript@5.2.2_vite@4.5.1_vue-tsc@1.8.15/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import helpers_mgWmzbOuLB from "/app/plugins/helpers.ts";
import intercom_client_vr7NPky0pK from "/app/plugins/intercom.client.ts";
import notify_oWSRP5vOkO from "/app/plugins/notify.ts";
import primevue_7rYYRZQLyx from "/app/plugins/primevue.ts";
import refresh_WGy8FnCQ38 from "/app/plugins/refresh.ts";
import reset_pinia_store_9Bziwmq9yi from "/app/plugins/reset-pinia-store.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import session_qoMCkkKHp8 from "/app/plugins/session.ts";
import talkjs_client_rQouZ3QEPc from "/app/plugins/talkjs.client.ts";
import translation_dm8974tryQ from "/app/plugins/translation.ts";
export default [
  revive_payload_client_jBVHgeKnnL,
  unhead_19Zp1zMIen,
  router_lliErzVZtL,
  payload_client_fuSQrSjHF9,
  plugin_vue3_onvedoSV5x,
  components_plugin_KR1HBZs4kY,
  prefetch_client_EDWcanDoTw,
  chunk_reload_client_wqazCpyqmY,
  check_outdated_build_client_LmOXZwF0bS,
  helpers_mgWmzbOuLB,
  intercom_client_vr7NPky0pK,
  notify_oWSRP5vOkO,
  primevue_7rYYRZQLyx,
  refresh_WGy8FnCQ38,
  reset_pinia_store_9Bziwmq9yi,
  sentry_client_shVUlIjFLk,
  session_qoMCkkKHp8,
  talkjs_client_rQouZ3QEPc,
  translation_dm8974tryQ
]