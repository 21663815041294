<script setup lang="ts">
import type { PrimevueButton } from '@/types/primevue-button'

const searchButton = ref<PrimevueButton | null>(null)
const isSearchOpen = ref(false)
const closeSearch = () => {
  isSearchOpen.value = false
  searchButton.value?.$el?.focus()
}
</script>

<template>
  <div>
    <Button
      ref="searchButton"
      class="p-button-rounded p-button-icon-only p-button-info md:hidden"
      label="Toggle Search"
      @click="isSearchOpen = !isSearchOpen"
      @keydown.escape="closeSearch"
    >
      <i
        v-if="!isSearchOpen"
        class="pi pi-search"
      />
      <i
        v-else
        class="pi pi-times"
      />
    </Button>
    <div
      :class="[
        { hidden: !isSearchOpen },
        'p-fluid absolute left-2 right-2 top-[72px] z-50 rounded-md bg-white p-4 shadow-around shadow-gray-400 md:relative md:top-auto md:flex md:w-auto md:border-0 md:shadow-none',
      ]"
      @keydown.escape="closeSearch"
    >
      <slot :close-search="closeSearch" />
    </div>
  </div>
</template>
