import type { NuxtAppWithPinia } from '@/types/nuxtApp'
export default defineNuxtPlugin(async (nuxtApp) => {
  const { $pinia } = nuxtApp as NuxtAppWithPinia
  const authStore = useAuthStore($pinia)
  const customerStore = useCustomerStore($pinia)
  const creatorStore = useCreatorStore($pinia)
  const refreshTokenCookie = useCookie('refresh_token')
  const { getFavoriteGigIds, getFavoriteCreatorIds } = useFavoritesStore($pinia)
  if (refreshTokenCookie.value) {
    authStore.refreshToken = refreshTokenCookie.value
  }
  const accessTokenCookie = useCookie('access_token')
  if (accessTokenCookie.value) {
    authStore.token = accessTokenCookie.value
  } else if (
    refreshTokenCookie.value &&
    refreshTokenCookie.value !== 'facebook' &&
    refreshTokenCookie.value !== 'google'
  ) {
    await authStore.reclaimToken(refreshTokenCookie)
  } else {
    authStore.resetAuthData()
  }
  if (authStore.isLoggedIn) {
    await customerStore.fetchCustomerData()
    await getFavoriteGigIds()
    await getFavoriteCreatorIds()

    if (customerStore.isCreator) {
      await creatorStore.fetchCreatorData()
    }
  }
})
