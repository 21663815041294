import type { GenderAction } from 'account/types/actions'

// We have 3 possible values for gender field in api:
// "male", "female" - we send them as is
// "I'm not sure" - the whole field should be removed from api
export const useGenderUpdateAction = (
  newGenderValue?: string,
): GenderAction | undefined => {
  const customerStore = useCustomerStore()
  const currentGenderValue = computed(
    () => customerStore.customer?.custom?.fields?.gender || '',
  )
  // new creator form - in that case user currently have empty field
  // and wants to set "I'm not sure"
  if (!currentGenderValue.value && !newGenderValue) {
    return undefined
  }

  // other cases
  const genderAction: GenderAction = {
    action: 'setCustomField',
    name: 'gender',
  }
  if (newGenderValue) {
    genderAction.value = newGenderValue
  }
  return genderAction
}
