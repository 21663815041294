import type { Price } from '@/types/price'

export default defineNuxtPlugin(() => {
  const priceNumber = (priceObject: Price) => {
    let formattedAmount = priceObject.centAmount

    if (priceObject.fractionDigits) {
      formattedAmount =
        priceObject.centAmount / 10 ** priceObject.fractionDigits
    }

    return formattedAmount
  }
  return {
    provide: {
      priceNumber: (priceObject: Price) => priceNumber(priceObject),
      price: (priceObject: Price, maxFractionDigits?: number, fee = 0) => {
        const price = priceNumber(priceObject) - fee
        const maximumFractionDigits =
          maxFractionDigits ??
          (String(price) === price.toFixed() ? 0 : priceObject.fractionDigits)

        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: priceObject.currencyCode,
          maximumFractionDigits,
          minimumFractionDigits: maximumFractionDigits,
        }).format(price)
      },
      priceObject: (value: number): Price => {
        return {
          currencyCode: 'USD',
          centAmount: Math.round(value * 100),
          fractionDigits: 2,
          type: 'centPrecision',
        }
      },
      urlToFile: async (url: string): Promise<File> => {
        const response = await fetch(url)
        const blob = await response.blob()
        const file = new File([blob], /[^/]*$/.exec(url)?.[0] || 'file', {
          type: blob.type,
        })

        return file
      },
      kebabize: (str: string): string => {
        return str
          .split('')
          .map((letter, idx) => {
            return letter.toUpperCase() === letter
              ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
              : letter
          })
          .join('')
      },
      getSizeString: (size: number): string => {
        const kb = size / 1024 / 1024
        return `${kb.toFixed(2)} MB`
      },
      generateDataImgPlaceholder: (w: Number, h?: Number): string =>
        `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 ${w} ${
          h || w
        }'%3E%3C/svg%3E`,
      parseReviewScore: (rating?: number): string =>
        rating?.toFixed(1) || 'N/A',
      formatToCompactNumber: (number: number) =>
        new Intl.NumberFormat('en', { notation: 'compact' }).format(number),
    },
  }
})
