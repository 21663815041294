<script setup lang="ts">
import type MenuDropdownComponent from './BaseMenuDropdown.vue'

import type { PrimevueButton } from '@/types/primevue-button'
import type { DropdownPosition, DropdownItem } from '@/types/menu-dropdown'
// TODO: types has to be defined in file: https://github.com/vuejs/core/issues/4294
interface MenuDropdown {
  items: DropdownItem[]
  dropdownPos: DropdownPosition
  triggerLabel: string
  triggerEl?: HTMLButtonElement | null
  dropdownClasses?: string
  buttonClass?: string
  type?: string
}
const buttonTrigger = ref<HTMLButtonElement | null | undefined>(null)
const props = withDefaults(defineProps<MenuDropdown>(), {
  dropdownPos: 'left',
  triggerEl: null,
  dropdownClasses: '',
  buttonClass: 'p-button-plain p-button-text',
  type: undefined,
})
const menuDropdownRef = ref<InstanceType<typeof MenuDropdownComponent> | null>(
  null,
)
const dropdownTrigger = ref<PrimevueButton | null>(null)

const isMenuOpen = ref(false)

const toggleMenu = () => {
  if (!dropdownTrigger.value) {
    buttonTrigger.value = props.triggerEl
  }
  isMenuOpen.value = !isMenuOpen.value
}

const closeMenu = () => {
  isMenuOpen.value = false
  buttonTrigger.value?.focus()
}

const passFocus = () => {
  if (isMenuOpen.value) {
    menuDropdownRef.value?.wrapper?.focus()
  }
}

onMounted(() => {
  if (dropdownTrigger.value) {
    buttonTrigger.value = dropdownTrigger.value.$el
  }
})
</script>

<template>
  <div :class="{ relative: type }">
    <slot
      :label="triggerLabel"
      :toggle-menu="toggleMenu"
      :is-menu-open="isMenuOpen"
      :close-menu="closeMenu"
      :pass-focus="passFocus"
    >
      <Button
        ref="dropdownTrigger"
        icon="pi pi-ellipsis-v"
        class="p-button-icon-only"
        :class="buttonClass"
        aria-haspopup="menu"
        :aria-expanded="isMenuOpen"
        :label="triggerLabel"
        @click="toggleMenu"
        @keydown.escape="closeMenu"
        @keydown.tab="passFocus"
      >
        <i
          v-if="!isMenuOpen"
          class="pi pi-ellipsis-v"
        />
        <i
          v-else
          class="pi pi-times"
        />
      </Button>
    </slot>
    <BaseMenuDropdown
      v-if="isMenuOpen"
      ref="menuDropdownRef"
      :menu-trigger="buttonTrigger || triggerEl"
      :type="type"
      :dropdown-pos="dropdownPos"
      :dropdown-classes="dropdownClasses"
      :items="items"
      @close="closeMenu"
      @click-outside="closeMenu"
    />
  </div>
</template>
