import { skipHydrate } from 'pinia'
import type { CustomerData } from 'account/types/customer'
import type { Action } from 'account/types/actions'
import type { Params } from '@/types/params'
import type ResultResponse from '@/types/result-response'
import type { Reviews } from '@/types/reviews'
import type { Order } from '@/types/order'

export const useCustomerStore = defineStore('customerStore', () => {
  const { $notify } = useNuxtApp()
  // State
  const customer = ref<CustomerData | null>(null)
  const customerReviewsTotal = ref<number | null>(null)
  const customerOrdersTotal = ref<number | null>(null)
  const areOrdersProcessing = ref(false)

  // Computed
  const email = computed(() => customer.value?.email || '')
  const nameLetter = computed(() => customer.value?.middleName?.charAt(0) || '')
  const isCreator = computed(() => customer.value?.isCreator || false)
  const runtimeConfig = useRuntimeConfig()
  const pictureVersion = ref(Date.now())
  const profilePicture = computed(() => {
    return customer.value?.id
      ? `${runtimeConfig.public.uploadUrl}profile/${customer.value.id}?t=${pictureVersion.value}`
      : undefined
  })
  const authStore = useAuthStore()

  // Actions
  const fetchCustomerData = async () => {
    try {
      const { data, error } = await useApi<CustomerData>('me')

      if (error.value) {
        throw new Error(error.value.message)
      }

      if (data.value) {
        customer.value = data.value
      }
    } catch (error) {
      $notify({
        text: (error as Error).message || 'Failed to get user data',
        severity: 'error',
      })
    }
  }
  /**
   * update brand data with provided actions
   * @param actions - array of actions to update brand data
   * @param options - options for update
   * @param options.notify - show notification on error (look into try catch)
   * @param options.prefetch - fetch customer data before update (without ui changes, we just want current version)
   */
  const updateAccount = async (
    actions: Action[],
    options: {
      notify: boolean
      prefetchData?: boolean
    } = { notify: true, prefetchData: false },
  ) => {
    if (!customer.value) {
      $notify({
        text: 'Missing customer data',
        severity: 'error',
      })
      return false
    }

    if (options.prefetchData) {
      await fetchCustomerData()
    }

    try {
      const { data, error } = await useApi<CustomerData>('me', {
        method: 'post',
        body: {
          version: customer.value.version,
          actions,
        },
      })

      if (error.value) {
        throw new Error(error.value.message)
      }

      if (data.value) {
        customer.value = data.value
        return true
      } else {
        return false
      }
    } catch (error) {
      if (options.notify) {
        $notify({
          text: (error as Error).message || 'Failed to update account',
          severity: 'error',
        })
      }
      return false
    }
  }

  const deactivateAccount = async (
    reason: { label: string; code: number },
    message: string,
  ) => {
    try {
      const { data, error } = await useApi<CustomerData>('/me/deactivate', {
        method: 'post',
        isFormData: true,
        body: new URLSearchParams({
          reason: JSON.stringify({
            reason,
            message,
          }),
          token: authStore.refreshToken,
        }),
      })

      if (error.value) {
        throw new Error(error.value.message)
      }

      if (data.value) {
        customer.value = null
        authStore.resetAuthData()
        $notify('Account deactivated')
        navigateTo('/')
      }
    } catch (error) {
      $notify({
        text: (error as Error).message || 'Failed to deactivate account',
        severity: 'error',
      })
    }
  }
  const updatePassword = async (
    currentPassword: string,
    newPassword: string,
  ) => {
    try {
      const { error } = await useApi<unknown>('/customers/password', {
        method: 'post',
        isFormData: true,
        body: new URLSearchParams({
          currentPassword,
          newPassword,
        }),
      })

      if (error.value) {
        throw new Error(error.value.message)
      }

      customer.value = null
      authStore.resetAuthData()
      $notify('Password updated, log in using new password')
      navigateTo('/')
    } catch (error) {
      $notify({
        text: (error as Error).message || 'Failed to update password',
        severity: 'error',
      })
    }
  }
  const uploadPicture = async (image: File) => {
    try {
      const formData = new FormData()
      formData.append('profile', image)
      const { error } = await useApi<unknown>('profile', {
        method: 'post',
        isUploadForm: true,
        body: formData,
      })

      if (error.value) {
        throw new Error(error.value.message)
      }

      pictureVersion.value = Date.now()
      return true
    } catch (error) {
      return false
    }
  }
  const updateProfilePicture = async (picture: string | File | undefined) => {
    if (picture instanceof File) {
      const file = picture
      return await uploadPicture(file)
    } else {
      return true
    }
  }
  const checkReviews = async (params: Params) => {
    try {
      // params are not encoded properly (unwanted '%25' is added') so we need to pass query manually
      const query = `limit=${params.limit}&where=${params.where}`
      const { data, error } = await useApi<Reviews>(`reviews?${query}`)

      if (error.value) {
        throw new Error(error.value.message)
      }

      if (data.value?.total) {
        customerReviewsTotal.value = data.value.total
      }
    } catch (error) {
      $notify({
        text: (error as Error).message || 'Failed to get reviews data',
        severity: 'error',
      })
    }
  }

  const checkOrders = async (params: Params) => {
    try {
      const { data, error } = await useApi<ResultResponse<Order>>('me/orders', {
        params,
      })

      if (error.value || !data.value) {
        throw new Error(error.value?.message || 'Failed to get orders data')
      }

      if (data.value?.total) {
        customerOrdersTotal.value = data.value.total
      }
    } catch (error) {
      $notify({
        text: (error as Error).message,
        severity: 'error',
      })
    }
  }

  return {
    customer: skipHydrate(customer),
    customerReviewsTotal: skipHydrate(customerReviewsTotal),
    customerOrdersTotal: skipHydrate(customerOrdersTotal),
    areOrdersProcessing,
    email,
    nameLetter,
    isCreator,
    profilePicture,
    fetchCustomerData,
    updateAccount,
    deactivateAccount,
    updatePassword,
    updateProfilePicture,
    checkReviews,
    checkOrders,
  }
})
