<script setup lang="ts">
onMounted(() => {
  const authStore = useAuthStore()

  if (!authStore.isLoggedIn) {
    return
  }

  const customerStore = useCustomerStore()

  if (window.FS) {
    window.FS.identify(customerStore.customer?.id, {
      displayName: customerStore.customer?.middleName,
      email: customerStore.email,
      reviewsWritten_int: 14,
    })
  }

  if (customerStore.customer) {
    const { $intercom } = useNuxtApp()
    $intercom().login(customerStore.customer)
    useKlaviyo('identify')
  }
})
</script>

<template>
  <NuxtLayout>
    <NuxtLoadingIndicator color="#0C74D3" />
    <NuxtPage />
  </NuxtLayout>
</template>

<style>
.full-bleed-bg {
  border-image: linear-gradient(#fcfcfc 0 0) fill 0/ /0 100vw 0 100vw;
}

.unread-item {
  @apply after:absolute after:right-1 after:top-0 after:block after:h-[7px] after:w-[7px] after:rounded-full after:bg-pink-100 after:content-[''];
}

.page-enter-active,
.page-leave-active,
.layout-enter-active,
.layout-leave-active {
  transition: opacity 0.2s ease;
}

.page-enter-from,
.page-leave-to,
.layout-enter-from,
.layout-leave-to {
  opacity: 0;
}
</style>
