import type { Params } from '@/types/params'
import type { Products } from '@/types/products'

export default async (params: Params): Promise<Products> => {
  try {
    const { data, error } = await useApi<Products>('products', {
      params,
    })

    if (error.value || !data.value) {
      throw new Error('No products data found.')
    }

    return data.value
  } catch (err: any) {
    throw createError({
      statusCode: 404,
      statusMessage: err.message,
      fatal: true,
    })
  }
}
