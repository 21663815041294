import { skipHydrate } from 'pinia'
import type {
  CreatorAction,
  PublishAction,
} from 'account/types/creator-actions'
import type { ProfileBody } from 'account/types/profile'
import type { Creator } from '@/types/creator'

export const useCreatorStore = defineStore('creatorStore', () => {
  const { $notify } = useNuxtApp()
  const customerStore = useCustomerStore()

  const creator = ref<Creator | null>(null)
  const profileId = computed(
    () => customerStore.customer?.custom?.fields?.profile?.id,
  )
  const hasPersonalInformation = computed(
    () => !!profileId && !!customerStore.customer?.addresses?.[0],
  )
  const hasProfessionalInformation = computed(() => {
    const description =
      creator.value?.masterData?.current?.description?.['en-US']
    return (
      (hasPersonalInformation && description && description.length > 10) ||
      false
    )
  })
  const isPublished = computed(
    () => creator.value?.masterData?.published || false,
  )
  const brandLogos = computed(
    () => creator.value?.masterData?.current?.masterVariant?.assets || [],
  )

  const fetchCreatorData = async () => {
    if (!profileId.value) {
      return
    }

    try {
      const { data, error } = await useApi<Creator>(
        `me/product/${profileId.value}`,
      )

      if (error.value) {
        throw new Error(error.value.message)
      }

      if (data.value) {
        creator.value = data.value
      }
    } catch (error) {
      $notify({
        text: (error as Error).message || 'Creator data fetch error',
        severity: 'error',
      })
    }
  }

  const createProfile = async (
    body: ProfileBody,
  ): Promise<{
    success: boolean
    errorMessage?: string
  }> => {
    try {
      const { data, error } = await useApi<Creator>('me/profile', {
        method: 'post',
        body,
      })

      if (error.value) {
        const errorMessage = error.value?.message || 'Create profile error'
        if (errorMessage.includes('duplicate value')) {
          const { newSlug } = suggestNewSlug(body.slug['en-US'])
          const newPersonalInformationStore = useNewPersonalInformationStore()

          newPersonalInformationStore.profileUrl = newSlug
          return {
            success: false,
            errorMessage: 'DuplicateField',
          }
        }
        throw new Error(errorMessage)
      }

      if (data.value) {
        creator.value = data.value
        return {
          success: true,
        }
      } else {
        return {
          success: false,
        }
      }
    } catch (error) {
      $notify({
        text: (error as Error).message,
        severity: 'error',
        sticky: true,
      })
      return {
        success: false,
      }
    }
  }
  /**
   * update creator data with provided actions
   * @param actions - array of actions to update creator data
   * @param options - object containing options for the update
   * @param options.prefetch - fetch creator data before the update (without ui changes, we just want current version)
   */
  const updateProfile = async (
    actions: CreatorAction[],
    options: {
      prefetchData?: boolean
    } = { prefetchData: false },
  ) => {
    if (options.prefetchData) {
      await fetchCreatorData()
    }
    if (!profileId.value) {
      $notify({
        text: 'Update profile error',
        severity: 'error',
      })
      return false
    }

    try {
      const { data, error } = await useApi<Creator>(
        `me/product/${profileId.value}`,
        {
          method: 'post',
          body: {
            version: creator.value?.version,
            actions,
          },
        },
      )

      if (error.value) {
        let errorMessage = error.value?.message || 'Update profile error'
        if (errorMessage.includes('duplicate value')) {
          const editPersonalInformationStore = useEditPersonalInformationStore()
          if (editPersonalInformationStore.profileUrl) {
            const { newSlug, newErrorMessage } = suggestNewSlug(
              editPersonalInformationStore.profileUrl as string,
            )
            editPersonalInformationStore.profileUrl = newSlug
            errorMessage = newErrorMessage
          }
        }
        throw new Error(errorMessage)
      }

      if (data.value) {
        creator.value = data.value
        return true
      } else {
        return false
      }
    } catch (error) {
      $notify({
        text: (error as Error).message,
        severity: 'error',
        sticky: true,
      })
      return false
    }
  }
  const publishProfile = async () => {
    if (!profileId.value) {
      return false
    }

    try {
      const { data, error } = await useApi<Creator>(
        `me/product/${profileId.value}`,
        {
          method: 'post',
          body: {
            version: creator.value?.version,
            actions: [
              {
                action: 'publish',
              } as PublishAction,
            ],
          },
        },
      )

      if (error.value) {
        throw new Error(error.value.message)
      }

      if (data.value) {
        creator.value = data.value
      }
      return true
    } catch (error) {
      return false
    }
  }

  return {
    creator: skipHydrate(creator),
    profileId,
    hasPersonalInformation,
    hasProfessionalInformation,
    isPublished,
    brandLogos,
    fetchCreatorData,
    createProfile,
    updateProfile,
    publishProfile,
  }
})
