export default defineNuxtRouteMiddleware(async (to) => {
  const { favId, favType } = to.query

  if (process.server || !favId || !favType) {
    return
  }

  const favoritesStore = useFavoritesStore()

  await favoritesStore.updateFavorite(
    favId as string,
    favType as 'gig' | 'profile',
  )

  return navigateTo(to.path)
})
