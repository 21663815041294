import { object, array, mixed, string } from 'yup'
import {
  requiredAssetsNumber,
  requiredLogosNumber,
} from 'gig/data/media-requirements.json'

export default object({
  // There's additional gallery step validation in gigCreateGalleryStore.isValid
  assets: array()
    .required()
    .min(
      requiredAssetsNumber,
      `Upload at least ${requiredAssetsNumber} videos or images`,
    ),
  brandIds: array()
    .required()
    .min(requiredLogosNumber, `Upload at least ${requiredLogosNumber} logo`),
})

export const assetSchema = object({
  asset: mixed().required('Add image or video'),
  assetAlt: string().max(50, 'This field must be at most 50 characters'),
})

export const brandSchema = object({
  image: mixed().required('Add image'),
  name: string().required().max(50, 'This field must be at most 50 characters'),
})
