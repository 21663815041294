import { storeToRefs } from 'pinia'

export default function () {
  const {
    conversations,
    getActiveConversations,
    getUnreadConversationsIds,
    hasUnreadConversation,
    isFetching,
    isFetched,
  } = storeToRefs(useChatStore())
  const { fetchInbox, fetchUnreads } = useChatStore()

  return {
    conversations,
    getActiveConversations,
    getUnreadConversationsIds,
    hasUnreadConversation,
    isFetching,
    isFetched,
    fetchInbox,
    fetchUnreads,
  }
}
