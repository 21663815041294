import { skipHydrate } from 'pinia'
import type { CookieRef } from 'nuxt/dist/app/composables'
import type { AccessToken, Token } from 'auth/types/token'
import type { Customer } from 'account/types/customer'
import { fbInitAndLogout } from 'auth/integrations/facebook'
import { EContent } from 'auth/enums/dialog-contents'

export const useAuthStore = defineStore('authStore', () => {
  const { $notify } = useNuxtApp()

  const token = ref('')
  const refreshToken = ref('')
  const isProcessing = ref(false)
  const isLoginProcessing = ref(false)
  const isDialogVisible = ref(false)
  const dialogContent = ref(EContent.SIGN_IN)
  const email = ref('')

  const isLoggedIn = computed(() => !!token.value)

  const switchContent = (value: EContent) => {
    dialogContent.value = value
  }

  const register = async (
    password: string,
    name: string,
    type: 'buyer' | 'creator',
  ) => {
    try {
      isProcessing.value = true
      const { data, error } = await useApi<Customer>('customers', {
        method: 'post',
        isFormData: true,
        body: new URLSearchParams({ email: email.value, password, name, type }),
      })

      if (error.value) {
        throw new Error(error.value.message)
      }

      $notify('Account created successfully')
      email.value = ''
      return data.value?.customer
    } catch (error) {
      $notify({
        text: (error as Error)?.message || 'Register error',
        severity: 'error',
      })
      return false
    } finally {
      isProcessing.value = false
    }
  }

  const setAuthAndCustomerData = async ({
    newAccessToken,
    maxAge,
    newRefreshToken,
    isExternalAuthType,
  }: {
    newAccessToken?: string
    maxAge?: number
    newRefreshToken?: string
    isExternalAuthType: boolean
  }) => {
    if (newAccessToken) {
      const accessTokenCookie = useCookie('access_token', {
        maxAge,
      })
      accessTokenCookie.value = newAccessToken
      token.value = newAccessToken
    }
    if (newRefreshToken) {
      const refreshTokenCookie = useCookie('refresh_token', {
        maxAge: isExternalAuthType ? maxAge : 200 * 24 * 60 * 60,
      })
      refreshTokenCookie.value = newRefreshToken
      refreshToken.value = newRefreshToken
    }

    const redirectUrl = useRoute().query?.redirect?.toString()
    const currentUrl = useRoute().path
    const customerStore = useCustomerStore()
    await customerStore.fetchCustomerData()

    if (customerStore.isCreator) {
      const creatorStore = useCreatorStore()
      await creatorStore.fetchCreatorData()

      if (redirectUrl === currentUrl) {
        isLoginProcessing.value = false
        return
      } else if (redirectUrl) {
        isLoginProcessing.value = false
        await navigateTo(redirectUrl)
      } else if (creatorStore.isPublished) {
        await navigateTo('/account/dashboard')
      } else {
        await navigateTo('/account/creator/new')
      }
    } else if (redirectUrl === currentUrl) {
      isLoginProcessing.value = false
      return
    } else if (redirectUrl) {
      isLoginProcessing.value = false
      await navigateTo(redirectUrl)
    } else {
      isLoginProcessing.value = false
    }

    const { getFavoriteGigIds, getFavoriteCreatorIds } = useFavoritesStore()
    await getFavoriteGigIds()
    await getFavoriteCreatorIds()
    await useNuxtApp().$getChat().login()
    if (customerStore?.customer) {
      await useNuxtApp()
        .$intercom()
        .login(customerStore?.customer)
    }
    $notify('Logged in successfully')
    isDialogVisible.value = false
  }

  const login = async (username: string, password: string) => {
    try {
      isProcessing.value = true
      isLoginProcessing.value = true
      const { data, error } = await useApi<Token>('oauth/customers/token', {
        method: 'post',
        isFormData: true,
        body: new URLSearchParams({ username, password }),
      })

      if (error.value) {
        throw new Error(error.value?.message)
      }

      await setAuthAndCustomerData({
        newAccessToken: data.value?.access_token,
        maxAge: data.value?.expires_in,
        newRefreshToken: data.value?.refresh_token,
        isExternalAuthType: false,
      })
    } catch (error) {
      $notify({
        text: (error as Error)?.message || 'Login error',
        severity: 'error',
      })
      isLoginProcessing.value = false
    } finally {
      isProcessing.value = false
    }
  }

  const resetAuthData = () => {
    const accessTokenCookie: CookieRef<string | null> =
      useCookie('access_token')
    accessTokenCookie.value = null
    const refreshTokenCookie: CookieRef<string | null> =
      useCookie('refresh_token')
    refreshTokenCookie.value = null
    token.value = ''
    refreshToken.value = ''
    const creatorStore = useCreatorStore()
    creatorStore.$reset()
    const customerStore = useCustomerStore()
    customerStore.$reset()
  }

  const logout = async () => {
    try {
      isProcessing.value = true
      const { error } = await useApi<unknown>('oauth/token/revoke', {
        method: 'post',
        isFormData: true,
        body: new URLSearchParams({ token: token.value }),
      })

      if (error.value) {
        throw new Error(error.value?.message)
      }

      if (refreshToken.value === 'facebook') {
        fbInitAndLogout()
      }
      resetAuthData()
      const favoritesStore = useFavoritesStore()
      favoritesStore.gigIds = []
      favoritesStore.creatorIds = []
      useNuxtApp().$getChat().logout()
      $notify('You have been successfully logged out')
      navigateTo('/')
    } catch (error) {
      $notify({
        text: (error as Error)?.message || 'Logout error',
        severity: 'error',
      })
    } finally {
      isProcessing.value = false
    }
  }

  const reclaimToken = async (
    refTokenToReclaim: CookieRef<string | null | undefined>,
  ) => {
    try {
      // useCookie has to be defined before $fetch https://github.com/nuxt/framework/issues/4925
      const accessTokenCookie = useCookie('access_token', {
        maxAge: 172800,
      })

      const { data, error } = await useApi<AccessToken>('oauth/token', {
        method: 'post',
        isFormData: true,
        body: new URLSearchParams({
          refresh_token: refTokenToReclaim.value as string,
        }),
      })

      if (error.value) {
        throw new Error(error.value?.message)
      }

      const newToken = data.value?.access_token || ''
      if (newToken) {
        accessTokenCookie.value = newToken
        token.value = newToken
      }
    } catch (error) {
      refTokenToReclaim.value = null
    }
  }

  const recoverPassword = async (email: string) => {
    try {
      isProcessing.value = true
      const { error } = await useApi<unknown>('customers/password-token', {
        method: 'post',
        isFormData: true,
        body: new URLSearchParams({ email }),
      })

      const successNotify = () =>
        $notify({
          text: 'If your email account exists in our system, you will be emailed a link with steps to reset your password.',
          sticky: true,
        })

      if (error.value) {
        if (error.value?.message?.includes('was not found')) {
          successNotify()
        } else {
          $notify({
            text: error.value?.message || 'Recover password error',
            severity: 'error',
          })
        }
        throw new Error(error.value?.message)
      } else {
        successNotify()
      }

      return true
    } catch (error) {
      return false
    } finally {
      isProcessing.value = false
    }
  }

  const setNewPassword = async (recoveryToken: string, newPassword: string) => {
    try {
      isProcessing.value = true
      const { data, error } = await useApi<Customer>(
        'customers/password/reset',
        {
          method: 'post',
          isFormData: true,
          body: new URLSearchParams({ token: recoveryToken, newPassword }),
        },
      )

      if (error.value) {
        throw new Error(error.value?.message)
      }

      if (data.value?.customer) {
        const customerStore = useCustomerStore()
        customerStore.customer = data.value.customer
      }
      $notify('Your password has been changed successfully')
      return true
    } catch (error) {
      $notify({
        text: (error as Error)?.message || 'New password error',
        severity: 'error',
      })
      return false
    } finally {
      isProcessing.value = false
    }
  }

  const confirmEmail = async (tokenValue: string) => {
    try {
      isProcessing.value = true

      const { error } = await useApi<unknown>('customers/confirm', {
        method: 'post',
        isFormData: true,
        body: new URLSearchParams({ tokenValue }),
      })

      if (error.value) {
        throw new Error(error.value?.message)
      }

      return true
    } catch (error) {
      return false
    } finally {
      isProcessing.value = false
    }
  }

  return {
    token: skipHydrate(token),
    refreshToken: skipHydrate(refreshToken),
    isProcessing: skipHydrate(isProcessing),
    isLoginProcessing: skipHydrate(isLoginProcessing),
    isDialogVisible: skipHydrate(isDialogVisible),
    dialogContent: skipHydrate(dialogContent),
    email: skipHydrate(email),
    isLoggedIn,
    switchContent,
    register,
    setAuthAndCustomerData,
    login,
    logout,
    reclaimToken,
    recoverPassword,
    setNewPassword,
    resetAuthData,
    confirmEmail,
  }
})
