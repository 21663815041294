import { storeToRefs } from 'pinia'

type WindowWithKlaviyo = Window & {
  klaviyo: { [key: string]: any }
}

declare const window: WindowWithKlaviyo

export default (name: string, payload: { [key: string]: any } = {}) => {
  const { customer, isCreator } = storeToRefs(useCustomerStore())

  if (!window.klaviyo) {
    console.warn('Klaviyo events are not tracked.')
    return
  }

  if (name === 'identify' && customer.value) {
    const customerType = isCreator.value ? 'creator' : 'brand'
    window.klaviyo.identify({
      $email: customer.value.email,
      customerType,
    })

    payload.customerType = customerType
  }

  if (customer.value) {
    payload.customerId = customer.value.id
    payload.customerEmail = customer.value.email
  }

  window.klaviyo.push(['track', name, payload])
}
